import React from "react";

import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import SpecificIndBanner from "../../../components/shared/SpecificIndustries/SpecificIndBanner";


import { Container } from "react-bootstrap";
import IndSoftwareMarketing from "../../../components/shared/SpecificIndustries/IndSoftwareMarketing";

import SOFTBANNER from "../../../assets/img/soft_banner.png";

import IDE from "../../../assets/mntechdigital-icon/software/ide_1.png";
import CMS from "../../../assets/mntechdigital-icon/software/cms_2.png";
import CREMS from "../../../assets/mntechdigital-icon/software/crems_3.png";
import DAS from "../../../assets/mntechdigital-icon/software/das_4.png";
import PS from "../../../assets/mntechdigital-icon/software/ps_5.png";
import SS from "../../../assets/mntechdigital-icon/software/ss_6.png";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const Software = () => {
  // banner description for health care industry
  const bannerDesc = {
    id: 2017,
    indName: "Software",
    indBannerDesc: `Software in software business refers to the various types
     of technology and tools that software companies use to develop, test,
      market, and distribute their software products. Software business plays 
      an important role in the development, testing, marketing, and distribution 
      of software products. These tools and technologies can help software companies 
      improve their efficiency, productivity, and revenue.`,
  };

  // industries
  const specificInd = [
    {
      id: 111771,
      indName: "Integrated Development Environments",
      indDesc: `Integrated Development Environments (IDEs) used by developers to write, test, and debug code.`,
      indImg: IDE,
    },
    {
      id: 111772,
      indName: "Content Management Systems",
      indDesc: `Content Management Systems (CMS) used to create and manage digital content, such as websites, blogs, and online stores.`,
      indImg: CMS,
    },
    {
      id: 111773,
      indName: "Customer Relationship Management ",
      indDesc: `Customer Relationship Management (CRM) software used by sales and marketing teams to manage customer data, track leads, and improve customer engagement.`,
      indImg: CREMS,
    },
    {
      id: 111774,
      indName: "Data analysis software",
      indDesc: `Data analysis software used by businesses to gather, process, and analyze large amounts of data, such as business intelligence software and data visualization tools.`,
      indImg: DAS,
    },
    {
      id: 111775,
      indName: "Productivity software",
      indDesc: `Productivity software used to increase efficiency and streamline workflow, such as task management software and team collaboration tools.`,
      indImg: PS,
    },
    {
      id: 111776,
      indName: "Security software",
      indDesc: `Security software used to protect computer systems from cyber threats, such as antivirus software and firewalls.`,
      indImg: SS,
    },
  ];

  return (
    <section className="mb-5">
      <PageHelmet
        title={helmetContent?.industry?.software?.title}
        description={helmetContent?.industry?.erp?.desc}
      />
      <SpecificIndBanner INDBANNER={SOFTBANNER} bannerDesc={bannerDesc} />
      <Container>
        <IndSoftwareMarketing
          specificInd={specificInd}
          indName={bannerDesc?.indName}
        />
        <LetUsHelp  />
      </Container>
    </section>
  );
};

export default Software;
