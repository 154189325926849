import React from "react";

import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import SpecificIndBanner from "../../../components/shared/SpecificIndustries/SpecificIndBanner";


import EDUBANNER from "../../../assets/img/edu_banner.png";
import { Container } from "react-bootstrap";
import IndSoftwareMarketing from "../../../components/shared/SpecificIndustries/IndSoftwareMarketing";

import LMS from "../../../assets/mntechdigital-icon/education/lms_1.png";
import ES from "../../../assets/mntechdigital-icon/education/es_2.png";
import SIS from "../../../assets/mntechdigital-icon/education/sis_3.png";
import DW from "../../../assets/mntechdigital-icon/education/dw_4.png";
import LLS from "../../../assets/mntechdigital-icon/education/lls_5.png";
import VR from "../../../assets/mntechdigital-icon/education/vr_6.png";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const Education = () => {
  // banner description for health care industry
  const bannerDesc = {
    id: 2016,
    indName: "Education",
    indBannerDesc: `Software plays a crucial role in the education industry,
     as it provides educators and students with tools and resources that facilitate 
     learning, increase efficiency, and improve educational outcomes. software has 
     transformed the education industry by providing educators and students with new 
     tools and resources to enhance learning and improve educational outcomes. 
    `,
  };

  // industries
  const specificInd = [
    {
      id: 111660,
      indName: "Learning Management Systems",
      indDesc: `This software is used by schools and universities to manage and deliver online courses, track student progress, and provide resources such as multimedia content and online assignments.
      `,
      indImg: LMS,
    },
    {
      id: 111661,
      indName: "Educational Software",
      indDesc: `This software includes a wide range of programs and applications designed to enhance learning, such as interactive textbooks, simulations, and educational games.`,
      indImg: ES,
    },
    {
      id: 111662,
      indName: "Student Information Systems",
      indDesc: `This software are used to manage student data, such as grades, attendance, and schedules, and to facilitate communication between students, parents, and educators.`,
      indImg: SIS,
    },
    {
      id: 111663,
      indName: "Digital Whiteboards",
      indDesc: `These interactive displays are used in classrooms to enable teachers to deliver engaging presentations, annotate lessons, and collaborate with students in real-time.`,
      indImg: DW,
    },
    {
      id: 111664,
      indName: "Language Learning Software",
      indDesc: `These programs are designed to help students learn a new language through interactive lessons, exercises, and practice activities.`,
      indImg: LLS,
    },
    {
      id: 111665,
      indName: "Virtual Reality  and Augmented Reality",
      indDesc: `These technologies are increasingly being used in education to provide students with immersive and interactive learning experiences, such as virtual field trips and simulations.`,
      indImg: VR,
    },
  ];

  return (
    <section className="mb-5">
      <PageHelmet
        title={helmetContent?.industry?.education?.title}
        description={helmetContent?.industry?.education?.desc}
      />
      <SpecificIndBanner INDBANNER={EDUBANNER} bannerDesc={bannerDesc} />
      <Container>
        <IndSoftwareMarketing
          specificInd={specificInd}
          indName={bannerDesc?.indName}
        />
        <LetUsHelp  />
      </Container>
    </section>
  );
};

export default Education;
