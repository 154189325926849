import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";
import CareerIntro from "../../../components/AboutUs/CareerWithUs/CareerIntro";
import JobPosition from "../../../components/AboutUs/CareerWithUs/JobPosition";
import axios from "axios";
import LoadingSpinner from "../../../components/shared/LoadingSpinner/LoadingSpinner";
import jobPostIcon from "../../../assets/mntechdigital-icon/job_post_apply/job_position.png";

const Career = () => {
  const [isJobPostLoading, setIsJobPostLoading] = useState(false);
  const [allJobPosts, setAllJobPosts] = useState([]);

  // state for update the job post
  const [isUpdated, setIsUpdateJob] = useState(false);

  // get all job posts
  useEffect(() => {
    setIsJobPostLoading(true);
    const getAllJobPost = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/jobposts`
        );

        if (response?.data?.status) {
          setAllJobPosts(response?.data?.results);
          setIsJobPostLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
      setIsJobPostLoading(false);
    };

    getAllJobPost();
  }, [isUpdated]);


  // function to get updated jobs after delete
  const getUpdatedJob = (jobId) => {
    const newJobs = allJobPosts.filter(
      (filterdJob) => filterdJob.job_id !== jobId
    );
    setAllJobPosts(newJobs);
  };

  return (
    <Container className="my-5 m-h-100">
      <PageHelmet
        title={helmetContent?.about?.career?.title}
        description={helmetContent?.about?.career?.desc}
      />
      <CareerIntro />

      {allJobPosts?.length ? (
        <>
          <section className="my-5">
            <div className="d-flex align-items-center gap-3 mb-4">
              <img
                src={jobPostIcon}
                alt="jobPostIcon"
                width="50px"
                height="50px"
              />
              <h2 className="fw-semibold fs-2">Open Job Positions</h2>
            </div>
            {isJobPostLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {allJobPosts.map((job) => (
                  <JobPosition
                    key={job?.job_id}
                    job_id={job?.job_id}
                    job_title={job?.job_title}
                    vacancy_quantity={job?.vacancy_quantity}
                    deadline={job?.deadline}
                    job_description={job?.job_description}
                    qualification={job?.qualification}
                    location={job?.location}
                    job_type={job?.job_type}
                    getUpdatedJob={getUpdatedJob}
                    setIsUpdateJob={setIsUpdateJob}
                  />
                ))}
              </>
            )}
          </section>
        </>
      ) : (
        ""
      )}
    </Container>
  );
};

export default Career;
