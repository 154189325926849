import React from "react";
import { Container } from "react-bootstrap";
import ContactUsButton from "../ContactUsButton/ContactUsButton";

const SpecificIndBanner = ({ INDBANNER, bannerDesc }) => {
  return (
    <header className="w-100 h-100 mb-5 position-relative">
      <img
        className="w-100 m-h-100"
        src={INDBANNER}
        alt={bannerDesc?.indName}
      />
      {/* <div className="text-white position-absolute md-top-15 md-left-25 top-25 left-15 w-75 ta-same"> */}
      <Container>
        <div className="text-white position-absolute md-top-10 top-25 banner-text ta-same">
        {/* <div className="text-white position-absolute md-top-10 top-25 banner-text ta-same"> */}
          <h1 className="text-white">{bannerDesc?.indName}</h1>
          <p className="fs-ind-banner">{bannerDesc?.indBannerDesc}</p>
          <ContactUsButton />
        </div>
      </Container>
    </header>
  );
};

export default SpecificIndBanner;
