import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import PARTNERIMG from "../../../assets/img/mntechdigital_partner.jpeg";
import "./BecomePartner.css";

const BecomePartner = () => {
  return (
    <section className="mt-7 mb-5" data-aos="fade-up">
      <h1 className="text-center">Want to Join Us as a Partner?</h1>
      <div className="border-b" />
      <Container>
        <div className="gap-4 mt-0 mt-md-3 mt-lg-4 d-flex md-f-col justify-content-between">
          <div className="md-w-100">
            <p className="partner-text mt-5 ta-same">
              A partnership can be a great way for two companies to combine
              their strengths and achieve mutual success. we want to share our
              expertise and resources around the world. So, contact us to see
              how you can join our family.
            </p>
            <p className="mt-5">
              <Link to="/join-as-partner" className="mntech-btn mx-4">
                Join As Partner
              </Link>
            </p>
          </div>

          <div className="md-w-100 ms-5 ms-lg-0">
            <img
              className="partner-image"
              src={PARTNERIMG}
              alt="partnerimage"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default BecomePartner;
