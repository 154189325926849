import { createBrowserRouter } from "react-router-dom";
import Root from "../layouts/Root";
import Career from "../pages/AboutUs/Career/Career";
import Blogs from "../pages/AboutUs/Media/Blogs/Blogs";
import SpecificBlog from "../pages/AboutUs/Media/SpecificBlog/SpecificBlog";
import MeetUs from "../pages/AboutUs/MeetUs/MeetUs";
import OurStory from "../pages/AboutUs/OurStory/OurStory";
import ContactUs from "../pages/ContactUs/ContactUs";
import Home from "../pages/Home/Home";
import JoinAsPartner from "../pages/Home/JoinAsPartner/JoinAsPartner";
import ECommerce from "../pages/Industries/ECommerce/ECommerce";
import Education from "../pages/Industries/Education/Education";
import Erp from "../pages/Industries/ERP/Erp";
import HealthCare from "../pages/Industries/HealthCare/HealthCare";
import IndustriesPage from "../pages/Industries/IndustriesPage/IndustriesPage";
import NonProfit from "../pages/Industries/NonProfit/NonProfit";
import RealEstate from "../pages/Industries/RealEstate/RealEstate";
import Retail from "../pages/Industries/Retail/Retail";
import Software from "../pages/Industries/Software/Software";
import StartUp from "../pages/Industries/StartUp/StartUp";
import Telecommunication from "../pages/Industries/Telecommunication/Telecommunication";
// import Portfolio from "../pages/Portfolio/Portfolio";
import DataEntry from "../pages/Services/DataEntry/DataEntry";
import DigitalMarketing from "../pages/Services/DigitalMarketing/DigitalMarketing";
import MobileAppDevelopment from "../pages/Services/MobileAppDevelopment/MobileAppDevelopment";
import QAndA from "../pages/Services/QAndA/QAndA";
import Services from "../pages/Services/ServicesPage/Services";
import UiAndUx from "../pages/Services/UiAndUx/UiAndUx";
import WebDevelopment from "../pages/Services/WebDevelopment/WebDevelopment";
import ErrorPage from "../pages/shared/ErrorPage";
import AdminDashboard from "../layouts/AdminDashboard";
import RequireAdmin from "./RequireAdmin";
import AdminContentPage from "../pages/AdminContentPage/AdminContentPage";
import SpecificJobDetails from "../pages/AboutUs/Career/SpecificJobDetails";
import JobApplyForm from "../pages/AboutUs/Career/JobApplyForm";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/join-as-partner",
        element: <JoinAsPartner />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      // {
      //   path: "/portfolio",
      //   element: <Portfolio />,
      // },
      {
        path: "/service/web-dev",
        element: <WebDevelopment />,
      },
      {
        path: "/service/mobile-app",
        element: <MobileAppDevelopment />,
      },
      {
        path: "/service/digital-marketing",
        element: <DigitalMarketing />,
      },
      {
        path: "/service/ui-ux",
        element: <UiAndUx />,
      },
      {
        path: "/service/data-entry",
        element: <DataEntry />,
      },
      {
        path: "/service/qa",
        element: <QAndA />,
      },
      {
        path: "/industries",
        element: <IndustriesPage />,
      },
      {
        path: "/industries/erp",
        element: <Erp />,
      },
      {
        path: "/industries/health-care",
        element: <HealthCare />,
      },
      {
        path: "/industries/telecommunication",
        element: <Telecommunication />,
      },
      {
        path: "/industries/real-estate",
        element: <RealEstate />,
      },
      {
        path: "/industries/e-com",
        element: <ECommerce />,
      },
      {
        path: "/industries/software",
        element: <Software />,
      },
      {
        path: "/industries/education",
        element: <Education />,
      },
      {
        path: "/industries/retail",
        element: <Retail />,
      },
      {
        path: "/industries/start-up",
        element: <StartUp />,
      },
      {
        path: "/industries/non-profit",
        element: <NonProfit />,
      },
      {
        path: "/about/our-story",
        element: <OurStory />,
      },
      {
        path: "/about/meet-us",
        element: <MeetUs />,
      },
      {
        path: "/about/blogs",
        element: <Blogs />,
      },
      {
        path: "/about/blog/:id",
        element: <SpecificBlog />,
      },
      {
        path: "/about/career",
        element: <Career />,
      },
      {
        path: "/about/application/:id",
        element: <SpecificJobDetails />,
      },
      {
        path: "/about/application/details/job-apply/:id",
        element: <JobApplyForm />,
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminDashboard />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/admin",
        element: <RequireAdmin />,
      },
      {
        path: `${"/admin/protected/content"}`,
        element: <AdminContentPage />,
      },
    ],
  },
]);
