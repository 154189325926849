import React from "react";
import PARTNER1 from "../../../../assets/img/partners_2.png";

const OurPartners = () => {
  return (
    <section className="text-center my-5">
      <div className="text-center mb-4" data-aos="fade-down">
        <h1>Our Partners</h1>
        <div className="border-b" />
      </div>
      <a href="https://d-logik.com/" target="_blank" rel="noreferrer" >
        <img className="partner-logo" src={PARTNER1} alt="d-logik" data-aos="fade-up" />
      </a>
    </section>
  );
};

export default OurPartners;
