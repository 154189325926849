import React from "react";
import { Container } from "react-bootstrap";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import IndSoftwareMarketing from "../../../components/shared/SpecificIndustries/IndSoftwareMarketing";
import SpecificIndBanner from "../../../components/shared/SpecificIndustries/SpecificIndBanner";



import ECOMBANNER from "../../../assets/img/e_com_banner.png";

import ECOMPLATFORM from "../../../assets/mntechdigital-icon/e_com/e-commerce.png";
import CMS from "../../../assets/mntechdigital-icon/e_com/cms.png";
import CRM from "../../../assets/mntechdigital-icon/e_com/crm.png";
import INVENTORY from "../../../assets/mntechdigital-icon/e_com/inventory.png";
import LOGISTIC from "../../../assets/mntechdigital-icon/e_com/logistics.png";
import REPORT from "../../../assets/mntechdigital-icon/e_com/report.png";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const ECommerce = () => {
  // banner description for health care industry
  const bannerDesc = {
    id: 2015,
    indName: "E-commerce",
    indBannerDesc: `E-commerce software refers to various applications and platforms that enable
     businesses to sell products and services online. E-commerce software can include various tools and
      functionalities, such as shopping cart software, payment gateways, shipping and inventory management tools,
       and customer management software.`,
  };

  // industries
  const specificInd = [
    {
      id: 111550,
      indName: "E-commerce Platforms",
      indDesc: `E-commerce platforms are software applications that enable companies to sell products and services online. They typically include features such as product listings, shopping carts, payment processing, and order fulfillment.`,
      indImg: ECOMPLATFORM,
    },
    {
      id: 111551,
      indName: "Content Management Systems",
      indDesc: `CMS are used to create, manage, and publish content on e-commerce websites. They allow businesses to easily update product listings, create landing pages, and manage customer reviews and ratings.`,
      indImg: CMS,
    },
    {
      id: 111552,
      indName: "Customer Relationship Management",
      indDesc: `CRM software is used to manage customer interactions and relationships. It helps e-commerce companies provide better customer service, personalize customer experiences, and improve customer retention.`,
      indImg: CRM,
    },
    {
      id: 111553,
      indName: "Inventory Management Software",
      indDesc: `Medical imaging software is used to store,
        manage, and analyze medical images, such
         as X-rays, CT scans, and MRIs. This software
        can help healthcare providersmake more
        accurate diagnoses and developmore
        effective treatment plans.`,
      indImg: INVENTORY,
    },
    {
      id: 111554,
      indName: "Logistics Management Software",
      indDesc: `Logistics management software is used to manage the shipping and delivery of e-commerce orders. It helps companies track shipments, manage delivery routes, and optimize delivery times.`,
      indImg: LOGISTIC,
    },
    {
      id: 111555,
      indName: "Analytics and Reporting Software",
      indDesc: `Patient engagement software is used to engage
        patients in their own care, through tools such as
        patient portals, mobile apps, and automated
        messaging. This can help improve patient
        satisfaction and adherence to treatment plans.
        `,
      indImg: REPORT,
    },
  ];

  return (
    <section className="mb-5">
      <PageHelmet
        title={helmetContent?.industry?.eCom?.title}
        description={helmetContent?.industry?.eCom?.desc}
      />
      <SpecificIndBanner INDBANNER={ECOMBANNER} bannerDesc={bannerDesc} />
      <Container>
        <IndSoftwareMarketing
          specificInd={specificInd}
          indName={bannerDesc?.indName}
        />
        <LetUsHelp  />
      </Container>
    </section>
  );
};

export default ECommerce;
