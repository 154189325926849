import React from "react";
import { Link, useRouteError } from "react-router-dom";

const ErrorPage = () => {
  const error = useRouteError();
  return (
    <div className="d-flex justify-content-center align-items-center w-100 m-h-100">
      <div className="text-center">
        <h1>{error?.status}</h1>
        <h5>{error?.statusText}</h5>
        <h4>Something went wrong!!!</h4>
        <Link to="/" className="btn btn-danger">Go Home</Link>
      </div>
    </div>
  );
};

export default ErrorPage;
