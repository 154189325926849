import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

const SpecificServBanner = ({ bannerElements }) => {
  const { serviceName, serviceDesc, serviceImg, btnAddress } = bannerElements;

  return (
    <header className="d-flex d-flex-center-both md-f-col gap-7">
   
      <div className="specific-banner-card shadow p-5">
        <h3 className="fw-bold">{serviceName}</h3>
        <p className="mt-4 fs-7 ta-same">{serviceDesc}</p>
        <div className="mt-4 mt-md-5 mt-lg-6">
          <a href={btnAddress} className="mntech-btn py-2 no-underline">
            EXPLORE
            <span>
              <MdKeyboardArrowDown className="fs-3 ms-2" />
            </span>
          </a>
        </div>
      </div>
   
      <div className="specific-serv-banner-img mt-5 mt-lg-0">
        <img src={serviceImg} alt="websiteBuilding" />
      </div>
    </header>
  );
};

export default SpecificServBanner;
