import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/shared/Footer/Footer";
import NavMenuBar from "../components/shared/NavMenuBar/NavMenuBar";
import ChangeRouteToTop from "../components/shared/ScrollToTopButton/ChangeRouteToTop";
import ScrollToTopButton from "../components/shared/ScrollToTopButton/ScrollToTopButton";

const Root = () => {
  return (
    <>
      <ScrollToTopButton />
      <ChangeRouteToTop />
      <NavMenuBar />
      <Outlet />
      <Footer />
    </>
  );
};

export default Root;
