import React from "react";
import { Table } from "react-bootstrap";

const AllJoinUsData = ({
  allJoinUsData,
  joinUsFormPageSize,
  handlePageSizeChange,
  joinUsFormPage,
  handleJoinUsFormPageChange,
  joinUsFormTotalPages,
}) => {
  return (
    <>
      {allJoinUsData.length ? (
        <>
          <div className="my-5">
            <h1>Join As A Partner Form Data</h1>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Country</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {allJoinUsData.map((user, idx) => (
                  <tr key={user?.id}>
                    <td className="fw-semibold">{idx + 1}</td>
                    <td className="fw-semibold">{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>{user.country}</td>
                    <td>{user.message}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Implement pagination controls for Join As A Partner Form */}
            <div className="pagination-controls">
              <span>Items per page:</span>
              <select
                value={joinUsFormPageSize}
                onChange={(e) =>
                  handlePageSizeChange("joinUsForm", Number(e.target.value))
                }
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
              <span>Page:</span>
              <button
                disabled={joinUsFormPage === 1}
                onClick={() => handleJoinUsFormPageChange(joinUsFormPage - 1)}
              >
                Prev
              </button>
              <span>{joinUsFormPage}</span>
              <button
                disabled={joinUsFormPage === joinUsFormTotalPages}
                onClick={() => handleJoinUsFormPageChange(joinUsFormPage + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </>
      ) : (
        <h2>There is no data from the join partner form</h2>
      )}
    </>
  );
};

export default AllJoinUsData;
