import React from "react";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./MissionAndVission.css";

const MissionAndVission = () => {
  return (
    <Container>
      <div className="w-100 mt-4 gap-4 py-5 md-f-col d-flex justify-content-center">
        <Card
          className="mission-card card-hover px-4 pb-5 w-100"
          data-aos="fade-down-right"
        >
          <Card.Body>
            <h5 className="fw-bold mission-title">MISSION</h5>
            <p className="ta-same">
              Our mission is to become the trusted farm to our clients opinion
              and become the leader of the information technology sector.
            </p>
          </Card.Body>
        </Card>
        <Card
          className="vission-card card-hover px-4 pb-5 w-100"
          data-aos="fade-down-left"
        >
          <Card.Body>
            <h5 className="fw-bold vission-title">VISSION</h5>
            <p className="ta-same">
              MNTECH DIGITAL’s objective is to become the fastest digital
              transformation company where we can engage people to the
              revolution through technology.
            </p>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default MissionAndVission;
