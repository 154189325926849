import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { VscLocation } from "react-icons/vsc";
import { BsTelephone } from "react-icons/bs";
import { TfiEmail } from "react-icons/tfi";
import { GrFacebookOption } from "react-icons/gr";
import { TfiLinkedin } from "react-icons/tfi";
import FOOTERLOGO from "../../../assets/img/footer_logo.svg";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="mt-5 mb-0 pt-5 footer">
      <Container>
        <Row xs={1} md={2} lg={4} className="mb-5 px-3 px-md-0">
          <Col>
            <>
              <div className="w-100">
                <Link to="/">
                  <img
                    className="mntech-footer-img"
                    src={FOOTERLOGO}
                    alt="mntechdigital"
                  />
                </Link>
                <p className="py-2 pe-4 ta-same">
                  Serving technology to Enterprises & Promote Businesses by
                  offering a variety of software solutions under one umbrella.
                </p>
              </div>
              <p className="footer-social">
                <a
                  href="https://www.facebook.com/profile.php?id=100086473733730"
                  className="footer-social-link footer-facebook rounded"
                  target="_blank"
                  rel="noreferrer"
                >
                  <GrFacebookOption className="w-100 h-100 p-2 cursor-pointer" />
                </a>
                <a
                  href="https://www.linkedin.com/company/mntech-digital/"
                  className="footer-social-link footer-linkedin rounded"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TfiLinkedin className="w-100 h-100 p-2 cursor-pointer" />
                </a>
              </p>
            </>
          </Col>
          <Col>
            <h4 className="ms-4">Featured Trends</h4>
            <ul className="mt-4">
              <li className="mb-3">
                <Link
                  to="/service/data-entry"
                  className="no-underline footer-route-link underline-hover"
                >
                  Data Entry
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="/industries/e-com"
                  className="no-underline footer-route-link underline-hover"
                >
                  E-Commerce
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="/service/digital-marketing"
                  className="no-underline footer-route-link underline-hover"
                >
                  Digital Marketing
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="/service/qa"
                  className="no-underline footer-route-link underline-hover"
                >
                  Quality Assurance
                </Link>
              </li>
            </ul>
          </Col>
          <Col>
            <h4 className="ms-4">Top Services</h4>
            <ul className="mt-4">
              <li className="mb-3">
                <Link
                  to="/service/web-dev"
                  className="no-underline footer-route-link underline-hover"
                >
                  Web Solution
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="/service/mobile-app"
                  className="no-underline footer-route-link underline-hover"
                >
                  Mobile Solution
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="/service/ui-ux"
                  className="no-underline footer-route-link underline-hover"
                >
                  UI/UX Design
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to="/service/digital-marketing"
                  className="no-underline footer-route-link underline-hover"
                >
                  Digital Marketing
                </Link>
              </li>
            </ul>
          </Col>
          <Col>
            <h4 className="ms-4">Get In Touch</h4>
            <ul className="mt-4">
              <li>
                <a
                  href="https://www.google.com/maps/place/MNTECH+DIGITAL/@22.686781,90.352887,20z/data=!4m6!3m5!1s0x37553594d3e577bf:0xce2cba69a27bf6c!8m2!3d22.6867403!4d90.3529076!16s%2Fg%2F11thg61t4c?hl=en"
                  target="_blank"
                  rel="noreferrer"
                  className="no-underline footer-route-link underline-hover"
                >
                  <span>
                    <VscLocation className="fs-2" />
                  </span>
                  <span className="ms-3">2432-000, Sagordi</span>
                  <br />
                  <span className="ms-5">Barisal, Bangladesh</span>
                </a>
              </li>
              <li className="my-4">
                <a
                  href="tel:+8801575130259"
                  className="no-underline footer-route-link underline-hover"
                >
                  <span>
                    <BsTelephone className="fs-4" />
                  </span>
                  <span className="ms-3 no-underline">
                    {" "}
                    P: +880 1575 130 259
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="mailto:contact@mntechdigital.com"
                  className="no-underline footer-route-link underline-hover"
                >
                  <span>
                    <TfiEmail className="fs-4" />
                  </span>
                  <span className="ms-3 no-underline">
                    E: contact@mntechdigital.com
                  </span>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <div>
          <p className="text-center py-3">
            Copyright © 2022{" "}
            <Link
              to="/"
              className="no-underline underline-hover mntech-primary-clr-text mntech-primary-clr-text-hover"
            >
              MNTECH DIGITAL
            </Link>
            <span>{" "}All Right Reserved</span>
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
