import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";

import INDBANNER from "../../../assets/img/ind_banner.png";

// inds icons
import ERP from "../../../assets/mntechdigital-icon/industries/erp.png";
import HEALTH from "../../../assets/mntechdigital-icon/industries/health_care.png";
import TCOMMUNICATION from "../../../assets/mntechdigital-icon/industries/telco.png";
import RESTATE from "../../../assets/mntechdigital-icon/industries/real_estate.png";
import ECOM from "../../../assets/mntechdigital-icon/industries/ecom.png";
import SOFTWARE from "../../../assets/mntechdigital-icon/industries/soft.png";
import EDUCATION from "../../../assets/mntechdigital-icon/industries/education.png";
import RETAIL from "../../../assets/mntechdigital-icon/industries/retail.png";
import STARTUP from "../../../assets/mntechdigital-icon/industries/start_up.png";
import NONPROFIT from "../../../assets/mntechdigital-icon/industries/non_profit.png";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

import "./IndustriesPage.css";
import { Link } from "react-router-dom";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const IndustriesPage = () => {
  // industries
  const industries = [
    {
      id: 111000,
      indName: "ERP",
      indDesc: `ERP stands for Enterprise Resource Planning. 
      It is a software system that integrates various business 
      processes such as finance, human resources, manufacturing, 
      supply chain management, customer relationship management, 
      and others into a single unified system. The primary goal 
      of an ERP system is to improve efficiency and productivity by 
      providing real-time information and automating various routine tasks.`,
      indImg: ERP,
    },
    {
      id: 111001,
      indName: "Health Care",
      indDesc: `Healthcare software refers to the various applications 
      and platforms that are used to manage and deliver healthcare services. 
      Healthcare software can be used by healthcare providers, patients, and 
      other stakeholders to improve the quality of care, increase efficiency, 
      and enhance communication.
      `,
      indImg: HEALTH,
    },
    {
      id: 111002,
      indName: "Telecommunication",
      indDesc: `Telecommunication software refers to various applications 
      and platforms that enable communication over long distances using 
      electronic devices such as computers, smartphones, and tablets. 
      Telecommunication software is used by individuals and organizations 
      to facilitate communication in real-time, regardless of geographic location.`,
      indImg: TCOMMUNICATION,
    },
    {
      id: 111003,
      indName: "Real Estate",
      indDesc: `Real estate software is a type of technology used in the real 
      estate industry to manage and facilitate various tasks related to buying, 
      selling, renting, and managing real estate properties. Real estate software 
      can be used by real estate agents, brokers, property managers, and investors 
      to improve efficiency, increase productivity, and enhance the overall customer experience.`,
      indImg: RESTATE,
    },
    {
      id: 111004,
      indName: "E-Commerce",
      indDesc: `E-commerce software refers to various applications and platforms that enable 
      usinesses to sell products and services online. E-commerce software can include various tools
      and functionalities, such as shopping cart software, payment gateways, shipping and inventory management tools,
      and customer management software.`,
      indImg: ECOM,
    },
    {
      id: 111005,
      indName: "Software",
      indDesc: `Software in software business refers to the various types of technology 
      and tools that software companies use to develop, test, market, and distribute their 
      software products. Software business plays an important role in the development, 
      testing, marketing, and distribution of software products. These tools and 
      technologies can help software companies improve their efficiency, productivity, and revenue.`,
      indImg: SOFTWARE,
    },
    {
      id: 111006,
      indName: "Education",
      indDesc: `Software plays a crucial role in the education industry, as it 
      provides educators and students with tools and resources 
      that facilitate learning, increase efficiency, and improve educational outcomes. 
      Software has transformed the education industry 
      by providing educators and students with new tools and resources 
      to enhance learning and improve educational outcomes.`,
      indImg: EDUCATION,
    },
    {
      id: 111007,
      indName: "Retail",
      indDesc: `Software plays a critical role in the retail industry, providing retailers 
      with the tools and resources they need to manage their operations, engage with customers, 
      and increase sales. By leveraging software, retailers can improve their efficiency, 
      streamline their processes, and gain insights into their business, enabling 
      them to compete effectively in a rapidly changing industry.`,
      indImg: RETAIL,
    },
    {
      id: 111008,
      indName: "Start Up",
      indDesc: `software is a crucial component of the startup industry, providing startups 
      with the tools and resources they need to build, grow, and scale their businesses 
      quickly and efficiently. By leveraging software, startups can streamline their 
      operations, improve their customer engagement, and gain insights into their business 
      performance, helping them to compete in a crowded marketplace and achieve long-term success.`,
      indImg: STARTUP,
    },
    {
      id: 111009,
      indName: "Non Profit",
      indDesc: `Software is an essential tool for the non-profit industry, 
      providing organizations with the resources they need to manage their 
      operations, engage with donors and volunteers, engage with supporters, 
      and achieve their mission. By leveraging software, non-profits can 
      increase their efficiency, improve their impact, and achieve greater success in serving their communities.`,
      indImg: NONPROFIT,
    },
  ];

  return (
    <section className="m-h-100">
      <PageHelmet
        title={helmetContent?.industry?.title}
        description={helmetContent?.industry?.desc}
      />
      <>
        <div className="w-100 h-100 mb-5">
          <img className="w-100 h-100" src={INDBANNER} alt="industriesBanner" />
        </div>
        <Container>
          <div data-aos="fade-down">
            <h2 className="text-center">Technological Soulutions</h2>
            <div className="border-b" />
          </div>
          <Row xs={1} md={2} lg={3} className="g-4 pt-5">
            {industries?.map((ind) => (
              <Col key={ind?.id} data-aos="fade-up" className="d-flex">
                <Link
                  to={`/industries/${
                    (ind?.id === 111000 && "erp") ||
                    (ind?.id === 111001 && "health-care") ||
                    (ind?.id === 111002 && "telecommunication") ||
                    (ind?.id === 111003 && "real-estate") ||
                    (ind?.id === 111004 && "e-com") ||
                    (ind?.id === 111005 && "software") ||
                    (ind?.id === 111006 && "education") ||
                    (ind?.id === 111007 && "retail") ||
                    (ind?.id === 111008 && "start-up") ||
                    (ind?.id === 111009 && "non-profit")
                  }`}
                  className="d-flex no-underline mntech-secondary-clr-text mntech-primary-clr-text-hover"
                >
                  <Card className="border-0 card-shadow card-hover cursor-pointer px-3">
                    <div className="mx-auto mt-4 ind-icon-container rounded-circle rounded-lg border-mntech">
                      <img
                        src={ind?.indImg}
                        className="icon-size-ind"
                        alt={ind?.indName}
                      />
                    </div>
                    <Card.Body className="card-height">
                      <Card.Title className="service-title text-center">
                        {ind?.indName}
                      </Card.Title>
                      <Card.Text className="mt-4 mntech-secondary-clr-text line-height-2 ta-same">
                        {ind?.indDesc}
                      </Card.Text>
                      <div className="mt-5 position-relative">
                        <p className="no-underline mntech-primary-clr-text mntech-primary-clr-text-hover position-fixed bottom-0">
                          <span>Read More</span>
                          <span>
                            <MdOutlineKeyboardArrowRight className="fs-4" />
                          </span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
          <>
            <LetUsHelp />
          </>
        </Container>
      </>
    </section>
  );
};

export default IndustriesPage;
