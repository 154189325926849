import React from "react";
import { Container } from "react-bootstrap";
import MEETUSBANNER from "../../../assets/img/meet_us_banner.png";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import ManagementProfile from "../../../components/AboutUs/ManagementProfile/ManagementProfile";

const MeetUs = () => {
  return (
    <section className="m-h-100">
      <div className="w-100 h-100 mb-5">
        <img
          className="w-100 h-100"
          src={MEETUSBANNER}
          alt="industriesBanner"
        />
      </div>
      <Container>
        <ManagementProfile />
        <>
          <LetUsHelp  />
        </>
      </Container>
    </section>
  );
};

export default MeetUs;
