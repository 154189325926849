import React from "react";
import { Container } from "react-bootstrap";
import TRIANGLE from "../../../assets/mntechdigital-icon/triangle.png";

const ServIndustries = ({ industries, industryImg }) => {
  return (
    <section className="mt-6">
      <div className="text-center" data-aos="fade-down">
        <h1>Industries</h1>
        <div className="border-b" />
      </div>
      <Container className="d-flex md-f-col justify-content-around gap-md-5 mt-5">
        <div
          className="pb-md-5 d-flex flex-row flex-lg-column flex-wrap md-w-100"
          data-aos="fade-right"
        >
          {industries.map((ind) => (
            <div
              key={ind?.id}
              className="d-flex align-items-center gap-3 my-3 px-5"
            >
              <img className="triangle-icon" src={TRIANGLE} alt="" />
              <span className="ind-font">{ind?.indName}</span>
            </div>
          ))}
        </div>
        <div className="d-flex align-items-center" data-aos="fade-left">
          <img className="ind-code md-w-100" src={industryImg} alt="coding" />
        </div>
      </Container>
    </section>
  );
};

export default ServIndustries;
