import React from "react";
import { Container } from "react-bootstrap";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import ServIndustries from "../../../components/shared/SpecificServices/ServIndustries";
import SpecificServBanner from "../../../components/shared/SpecificServices/SpecificServBanner";
import SpecificServDetails from "../../../components/shared/SpecificServices/SpecificServDetails";
import SpecificServTechnologies from "../../../components/shared/SpecificServices/SpecificServTechnologies";
import MOBILESERV from "../../../assets/img/mobile_serv.png";
import JAVA from "../../../assets/mntechdigital-icon/mobile/java.png";
import REACTNATIVE from "../../../assets/mntechdigital-icon/mobile/react_native.png";
import SWIFT from "../../../assets/mntechdigital-icon/mobile/swift.png";
import XAMARIN from "../../../assets/mntechdigital-icon/mobile/xamarin.png";
import INDMOBAPP from "../../../assets/img/ind_mob_app.png";


import "../../../styles/Style.css";
import ScrollToDown from "../../../components/shared/ScrollToTopButton/ScrollToDown";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const MobileAppDevelopment = () => {
  // website development banner elements
  const bannerElements = {
    id: 100002,
    serviceName: "Mobile app Development",
    serviceDesc: `Mobile app development is the process of creating software applications that run on mobile devices such as smartphones and tablets. Mobile apps can be developed for different platforms such as iOS, Android, and Windows Phone.
    `,
    serviceImg: MOBILESERV,
    btnAddress: "#explore",
  };

  // website service details
  const details = {
    firstParagraph: ` MNTECH DIGITAL team can help businesses to engage with customers in a more personalized and interactive way. By providing personalized content, push notifications, and in-app messaging, client businesses can build stronger relationships with their customers.
    Our team allow customers to access services and products from anywhere and at any time. This can help client businesses to reach customers who are on the go and provide them with a seamless user experience. Our team can help businesses to increase their brand recognition and awareness.`,
    secondParagraph: `By creating a branded app, businesses can reinforce their brand identity and improve their visibility among customers. We can provide client businesses with a competitive advantage by offering a unique and engaging customer experience. By providing features such as loyalty programs, rewards, and discounts, client businesses can differentiate themselves from their competitors and attract new customers. We can offers numerous benefits for client businesses and individuals looking to improve customer engagement, accessibility, brand recognition, sales, and revenue.`,
  };

  // technologies used for web
  const technologies = [
    {
      id: 7000,
      techImg: JAVA,
      techName: "Java",
    },
    {
      id: 7001,
      techImg: REACTNATIVE,
      techName: "React Native",
    },
    {
      id: 7002,
      techImg: SWIFT,
      techName: "Swift",
    },
    {
      id: 7003,
      techImg: XAMARIN,
      techName: "Xamarin",
    },
  ];

  // industries
  const industries = [
    {
      id: 6000,
      indName: "Telecommunication",
    },
    {
      id: 6001,
      indName: "Real Estate",
    },
    {
      id: 6002,
      indName: "E-commerce",
    },
    {
      id: 6003,
      indName: "Software",
    },
    {
      id: 6004,
      indName: "Education",
    },
    {
      id: 6005,
      indName: "Non Profit",
    },
  ];

  return (
    <section className="mt-5 m-h-100">
      <PageHelmet
        title={helmetContent?.ourServices?.mobileApp?.title}
        description={helmetContent?.ourServices?.mobileApp?.desc}
      />
      <Container>
        <SpecificServBanner bannerElements={bannerElements} />
        <ScrollToDown btnAddress={bannerElements?.btnAddress} />
        <SpecificServDetails details={details} />
        <SpecificServTechnologies technologies={technologies} />
        <ServIndustries industries={industries} industryImg={INDMOBAPP} />
        <LetUsHelp  />
      </Container>
    </section>
  );
};

export default MobileAppDevelopment;
