import React from "react";
// import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import HOMEBANNER from "../../../assets/img/home_banner.png";
import "./HomeBanner.css";

const HomeBanner = () => {
  return (
    <section className="mt-4 home-banner-container">
      <div className="hero-area mt-5">
        <Container className="intro-container">
          <div className="intro-textbox">
            <h1 className="intro-text-bold fs-sm-2">
              WE INSPIRE INNOVATION & <br /> BUSINESS GROWTH
            </h1>
            <h3 className="intro-text-light mb-4 mb-lg-5 fs-sm-1">
              THROUGH SOFTWARE SOLUTIONS
              <span className="text-block">{" "}AND CONTINUOUS SUPPORT.</span>
            </h3>
            {/* <Link to="/portfolio" className="mntech-btn no-underline">
              EXPLORE WITH US
            </Link> */}
          </div>
        </Container>
        <img
          className="banner-img"
          width="60%"
          src={HOMEBANNER}
          alt="HomeBanner"
        />
      </div>
    </section>
  );
};

export default HomeBanner;
