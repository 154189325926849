import React from 'react';
import ASPARTNER from "../../../../assets/img/as_partner.png";


const JoinAsPartnerBanner = () => {
    return (
        <header className="w-100 h-100 mb-5 position-relative header">
        <img className="w-100 m-h-100" src={ASPARTNER} alt="becomeOurPartner" />
        <div className="text-white text-center position-absolute top-0 z-index-100 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <div className="text-center">
            <h1 className="join-as-heading">Join us as a partner</h1>
          </div>
          <div className="position-absolute bottom-6">
            <h5 className="join-as-desc">
              We want partners to exchange our experience, values, and help to
              grow business.
            </h5>
            <p className="text-center mt-3">-Mosrur Rakayet,Co-Founder, MNTECH DIGITAL</p>
          </div>
        </div>
        <div className="overlay"></div>
      </header>
    );
};

export default JoinAsPartnerBanner;