import { useEffect, useState } from "react";
import axios from "axios";

const useJobDetails = (id) => {
  const [jobDetails, setJobDetails] = useState({});
  const [isJobDetailsLoading, setIsJobDetailsLoading] = useState(false);

  useEffect(() => {
    setIsJobDetailsLoading(true);

    const getOneJob = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/jobposts/${id}`
        );
        setJobDetails(response?.data?.results[0]);
      } catch (error) {
        console.log(error);
      } finally {
        setIsJobDetailsLoading(false);
      }
    };

    getOneJob();
  }, [id]);

  return { jobDetails, isJobDetailsLoading };
};

export default useJobDetails;
