import React from "react";
import { Container } from "react-bootstrap";
import SpecificIndBanner from "../../../components/shared/SpecificIndustries/SpecificIndBanner";
import ERPBANNER from "../../../assets/img/erp_banner.png";
import SpecificIndDetails from "../../../components/shared/SpecificIndustries/SpecificIndDetails";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const Erp = () => {
  // banner description for ERP industry
  const bannerDesc = {
    id: 2010,
    indName: "ERP",
    indBannerDesc: `ERP stands for Enterprise Resource Planning.
    It is a software system that integrates various business processes
    such as finance, human resources, manufacturing, supply chain management,
    customer relationship management, and others into a single unified system.
    The primary goal of an ERP system is to improve efficiency and productivity 
    by providing real-time information and automating various routine tasks.
    `,
  };

  // ERP industry details
  const indDetails = {
    firstParagraph: `ERP systems typically integrate multiple business functions such as accounting, inventory management, order management, human resources, supply chain management, and customer relationship management.
    The main purpose of an ERP system is to provide a single source of truth for all data and processes across the organization, enabling better decision-making and more efficient operations. For example, an ERP system can provide real-time inventory information to help businesses optimize their supply chain and reduce waste, `,
    secondParagraph: `or it can automate financial processes to streamline accounting and reduce errors.
    Overall, an ERP system is designed to improve visibility, efficiency, and productivity across the entire organization, from finance and accounting to manufacturing and distribution, and can help businesses to achieve their goals more effectively.
    MNTECH DIGITAL team of professionals will take care of your business's bottlenecks. Whether its development services, implementation, or even dedicated resources- get in touch with us for your ERP needs.
    `,
  };

  return (
    <section className="mb-5">
      <PageHelmet
        title={helmetContent?.industry?.erp?.title}
        description={helmetContent?.industry?.erp?.desc}
      />
      <SpecificIndBanner INDBANNER={ERPBANNER} bannerDesc={bannerDesc} />
      <Container>
        <SpecificIndDetails
          indDetails={indDetails}
          indName={bannerDesc?.indName}
        />
        <>
          <LetUsHelp />
        </>
      </Container>
    </section>
  );
};

export default Erp;
