import React from "react";
import NavMenuBar from "../components/shared/NavMenuBar/NavMenuBar";
import Footer from "../components/shared/Footer/Footer";
import { Outlet } from "react-router-dom";

function AdminDashboard() {
  return (
    <>
      <NavMenuBar />
      <Outlet />
      <Footer />
    </>
  );
}

export default AdminDashboard;
