import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

const IndSoftwareMarketing = ({ specificInd, indName }) => {
  return (
    <Container>
      <div data-aos="fade-down">
        <h2 className="text-center">How Software Can Help In {indName}</h2>
        <div className="border-b" />
      </div>
      <Row xs={1} md={2} lg={3} className="g-4 pt-5">
        {specificInd?.map((ind) => (
          <Col key={ind?.id} data-aos="fade-up">
            <Card className="h-100 border-0 card-shadow card-hover cursor-pointer px-3 pb-3">
              <div className="mx-auto ind-icon-container w-100">
                <img
                  src={ind?.indImg}
                  className="mt-4 icon-size-ind"
                  alt={ind?.indName}
                />
              </div>
              <Card.Body>
                <Card.Title className="text-center">{ind?.indName}</Card.Title>
                <Card.Text className="mt-4 mntech-secondary-clr-text line-height-2 ta-same">
                  {ind?.indDesc}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default IndSoftwareMarketing;
