import React from "react";
import { Container } from "react-bootstrap";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import IndSoftwareMarketing from "../../../components/shared/SpecificIndustries/IndSoftwareMarketing";
import SpecificIndBanner from "../../../components/shared/SpecificIndustries/SpecificIndBanner";

import TELECOMBANNER from "../../../assets/img/telecom_banner.png";

import NETMANAGEMENT from "../../../assets/mntechdigital-icon/telecom/net_management.png";
import BILLANDREV from "../../../assets/mntechdigital-icon/telecom/billandrevenue.png";
import CRM from "../../../assets/mntechdigital-icon/telecom/crm.png";
import INVENTORY from "../../../assets/mntechdigital-icon/telecom/inventory.png";
import NFV from "../../../assets/mntechdigital-icon/telecom/nfv.png";
import CEM from "../../../assets/mntechdigital-icon/telecom/cem.png";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const Telecommunication = () => {
  // banner description for health care industry
  const bannerDesc = {
    id: 2012,
    indName: "Telecommunication",
    indBannerDesc: `Telecommunication software refers to various applications and 
    platforms that enable communication over long distances using electronic devices
    such as computers, smartphones, and tablets. Telecommunication software is used by individuals
    and organizations to facilitate communication in real-time, regardless of geographic location.`,
  };

  // industries
  const specificInd = [
    {
      id: 111330,
      indName: "Network Management Software",
      indDesc: `Network management software is used to 
      monitor and manage telecommunication 
      networks, including wireless and wired 
      networks. It helps operators detect and 
      troubleshoot network issues, optimize
      network performance, and ensure network
      security.
      `,
      indImg: NETMANAGEMENT,
    },
    {
      id: 111331,
      indName: "Billing and Revenue Management ",
      indDesc: `Billing and revenue management software is 
      used to manage the billing and charging of 
      telecommunication services. It helps operators
      ensure accurate and timely billing, manage
      revenue streams, and prevent fraud.
      `,
      indImg: BILLANDREV,
    },
    {
      id: 111332,
      indName: "Customer Relationship Management",
      indDesc: `CRM software is used to manage customer
      interactions and relationships. It helps 
      telecommunication companies provide better
      customer service, personalize customer 
      experiences, and improve customer retention.      
      `,
      indImg: CRM,
    },
    {
      id: 111333,
      indName: "Inventory Management Software",
      indDesc: `Inventory management software is used to manage the hardware and software inventory of telecommunication companies. It helps operators track and manage their assets, optimize their inventory levels, and prevent stockouts.
      `,
      indImg: INVENTORY,
    },
    {
      id: 111334,
      indName: "Network Function Virtualization",
      indDesc: `NFV software is used to virtualize network functions, such as routers and switches. It helps telecommunication companies reduce hardware costs, improve network flexibility, and deploy new services more quickly.
      `,
      indImg: NFV,
    },
    {
      id: 111335,
      indName: "Customer Experience Management",
      indDesc: `CEM software is used to measure and manage the customer experience. It helps telecommunication companies gather customer feedback, identify areas for improvement, and optimize customer journeys.
      `,
      indImg: CEM,
    },
  ];
  return (
    <section className="mb-5">
      <PageHelmet
        title={helmetContent?.industry?.telecom?.title}
        description={helmetContent?.industry?.telecom?.desc}
      />
      <SpecificIndBanner INDBANNER={TELECOMBANNER} bannerDesc={bannerDesc} />
      <Container>
        <IndSoftwareMarketing
          specificInd={specificInd}
          indName={bannerDesc?.indName}
        />
        <LetUsHelp  />
      </Container>
    </section>
  );
};

export default Telecommunication;
