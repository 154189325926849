import React from "react";
import { Container } from "react-bootstrap";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import IndSoftwareMarketing from "../../../components/shared/SpecificIndustries/IndSoftwareMarketing";
import SpecificIndBanner from "../../../components/shared/SpecificIndustries/SpecificIndBanner";



import REALESTATEBANNER from "../../../assets/img/real_estate.png";

import PMS from "../../../assets/mntechdigital-icon/real_estate/pms.png";
import CRM from "../../../assets/mntechdigital-icon/real_estate/crm.png";
import REMS from "../../../assets/mntechdigital-icon/real_estate/rems.png";
import REIS from "../../../assets/mntechdigital-icon/real_estate/reis.png";
import RETM from "../../../assets/mntechdigital-icon/real_estate/retm.png";
import REVS from "../../../assets/mntechdigital-icon/real_estate/revs.png";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const RealEstate = () => {
  // banner description for health care industry
  const bannerDesc = {
    id: 2013,
    indName: "Real Estate",
    indBannerDesc: `Real estate software is a type of technology used in the real estate industry to manage
    and facilitate various tasks related to buying, selling, renting, and managing real estate properties. 
    Real estate software can be used by real estate agents, brokers, property managers, 
    and investors to improve efficiency, increase productivity, and enhance the overall customer experience.`,
  };

  // industries
  const specificInd = [
    {
      id: 111440,
      indName: "Property Management Software",
      indDesc: `Property management software is used to manage real estate properties, including rental properties, commercial properties, and vacation homes. It helps property managers automate tasks such as rent collection, maintenance requests, and lease renewals.`,
      indImg: PMS,
    },
    {
      id: 111441,
      indName: "Customer Relationship Management ",
      indDesc: `CRM software is used to manage customer interactions and relationships. It helps real estate companies provide better customer service, personalize customer experiences, and improve customer retention.
      `,
      indImg: CRM,
    },
    {
      id: 111442,
      indName: "Real Estate Marketing Software",
      indDesc: `Real estate marketing software is used to create and distribute marketing content, such as property listings, virtual tours, and property photos. It helps real estate companies attract more leads and convert them into customers.`,
      indImg: REMS,
    },
    {
      id: 111443,
      indName: "Real Estate Investment Software",
      indDesc: `Real estate investment software is used to analyze investment opportunities, such as rental properties, commercial properties, and vacation homes. It helps real estate investors evaluate potential returns, forecast cash flows, and make more informed investment decisions.`,
      indImg: REIS,
    },
    {
      id: 111444,
      indName: "Real Estate Transaction Management",
      indDesc: `Real estate transaction management software is used to manage the process of buying or selling a property. It helps real estate agents and brokers automate tasks such as document management, contract management, and closing coordination.`,
      indImg: RETM,
    },
    {
      id: 111445,
      indName: "Real Estate Valuation Software",
      indDesc: `Real estate valuation software is used to estimate the value of real estate properties. It helps real estate professionals and appraisers assess market trends, compare properties, and generate accurate property valuations.`,
      indImg: REVS,
    },
  ];

  return (
    <section className="mb-5">
      <PageHelmet
        title={helmetContent?.industry?.realEstate?.title}
        description={helmetContent?.industry?.realEstate?.desc}
      />
      <SpecificIndBanner INDBANNER={REALESTATEBANNER} bannerDesc={bannerDesc} />
      <Container>
        <IndSoftwareMarketing
          specificInd={specificInd}
          indName={bannerDesc?.indName}
        />
        <LetUsHelp  />
      </Container>
    </section>
  );
};

export default RealEstate;
