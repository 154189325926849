import React from "react";
import { Table } from "react-bootstrap";

const AllContactFormData = ({
  allContactFormData,
  contactFormPageSize,
  handlePageSizeChange,
  contactFormPage,
  handleContactFormPageChange,
  contactFormTotalPages,
}) => {
  return (
    <>
      {allContactFormData.length ? (
        <div className="mt-4 mb-5">
          <h1>Contact Us Form Data</h1>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Email</th>
                <th>Subject</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {allContactFormData.map((user, idx) => (
                <tr key={user?.id}>
                  <td className="fw-semibold">{idx + 1}</td>
                  <td className="fw-semibold">{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.subject}</td>
                  <td>{user.message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* Implement pagination controls for Contact Us Form */}
          <div className="pagination-controls">
            <span>Items per page:</span>
            <select
              value={contactFormPageSize}
              onChange={(e) =>
                handlePageSizeChange("contactForm", Number(e.target.value))
              }
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
            <span>Page:</span>
            <button
              disabled={contactFormPage === 1}
              onClick={() => handleContactFormPageChange(contactFormPage - 1)}
            >
              Prev
            </button>
            <span>{contactFormPage}</span>
            <button
              disabled={contactFormPage === contactFormTotalPages}
              onClick={() => handleContactFormPageChange(contactFormPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <h2>There is no data from the contact form</h2>
      )}
    </>
  );
};

export default AllContactFormData;
