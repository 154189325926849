import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import useJobDetails from "../../../hooks/getSingleJob";
import LoadingSpinner from "../../../components/shared/LoadingSpinner/LoadingSpinner";
import wishFlower from "../../../assets/img/flower.jpg";

const JobApplyForm = () => {
  const [isApplied, setIsApplied] = useState(false);
  const location = useLocation();
  const id = location.pathname.split("/").pop();
  const { jobDetails, isJobDetailsLoading } = useJobDetails(id);

  const {
    register,
    handleSubmit,
    formState: { errors, isLoading },
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    const existingJobIds =
      JSON.parse(localStorage.getItem("mntechJobApplyIds")) || [];

    const isAlreadyApplied = existingJobIds.includes(+id);
    setIsApplied(isAlreadyApplied);
  }, [id]);

  const handleUserJobApplyForm = async (data) => {
    // console.log(data);
    setValue("apply_position", jobDetails.job_title);

    const {
      name,
      email,
      phone,
      applicant_location,
      notice_period,
      department,
      apply_position,
      job_location,
      resume_cv,
    } = data;
    // console.log(data)
    if (
      name?.length === 0 ||
      email?.length === 0 ||
      phone?.length === 0 ||
      applicant_location?.length === 0 ||
      notice_period?.length === 0 ||
      department?.length === 0 ||
      apply_position?.length === 0 ||
      job_location?.length === 0
    ) {
      toast.error("Input field does not be empty!", {
        style: {
          borderRadius: "10px",
          background: "#222",
          color: "#fff",
        },
      });
      return;
    }
    if (resume_cv[0].size > 222999) {
      toast.error("File size must be less than 215kb", {
        style: {
          borderRadius: "10px",
          background: "#222",
          color: "#fff",
        },
      });
      return;
    }
    // console.log(resume_cv[0].size)
    const applyTime = new Date().getTime();

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("applicant_location", applicant_location);
      formData.append("notice_period", notice_period);
      formData.append("department", department);
      formData.append("apply_position", apply_position);
      formData.append("job_location", job_location);
      formData.append("resume_cv", resume_cv[0]);
      formData.append("apply_time", applyTime);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/jobApply`,
        formData
      );

      // const response = true
      // console.log(response);
      toast.success(`${response?.data?.message}`, {
        style: {
          borderRadius: "10px",
          background: "#222",
          color: "#fff",
        },
      });

      if (response?.data?.status) {
        // Get existing job IDs from localStorage or initialize as an empty array
        const existingJobIds =
          JSON.parse(localStorage.getItem("mntechJobApplyIds")) || [];

        // Add the new jobDetails.job_id to the existing array
        existingJobIds.push(jobDetails.job_id);

        // Set the updated array back to localStorage
        localStorage.setItem(
          "mntechJobApplyIds",
          JSON.stringify(existingJobIds)
        );

        setIsApplied(true);

        reset();
      }
    } catch (error) {
      toast.error(error?.message, {
        style: {
          borderRadius: "10px",
          background: "#222",
          color: "#fff",
        },
      });
    }
  };

  // console.log(jobDetails)

  return (
    <>
      {isJobDetailsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {isApplied ? (
            <div className="py-5">
              <div className="text-center d-flex-center-both my-5">
                <div className="py-5">
                  <h3>
                    <span>Thank you</span>
                    <img
                      src={wishFlower}
                      alt="wishing you"
                      width="160px"
                      height="120px"
                    />
                  </h3>
                  <p className="fs-5">
                    We will reach out to you if your qualifications meet our
                    requirements.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <>
              <form
                onSubmit={handleSubmit(handleUserJobApplyForm)}
                className="join-partner-form mx-auto mt-6 card p-5 shadow border-0"
                encType="multipart/form-data"
              >
                <div className="text-center mb-3">
                  <h2>Please Submit Your Resume Below</h2>
                  <p className="fs-5">
                    We will reach out to you if your qualifications meet our
                    requirements.
                  </p>
                  <p className="mntech-primary-clr-text">
                    <span className="fw-bold">Warning: </span>
                    <span>
                      Fill out this form carefully. You will never update your
                      information later.
                    </span>
                  </p>
                </div>
                <div className="w-100 mb-3">
                  <label className="fs-5 mb-1">Full Name</label>
                  <input
                    className="w-100 input-field px-1"
                    type="text"
                    name="name"
                    placeholder="John Doe"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <p className="mntech-primary-clr-text">
                      *Name is required!!!
                    </p>
                  )}
                </div>
                <div className="w-100 mb-3">
                  <label className="fs-5 mb-1">Email</label>
                  <input
                    className="w-100 input-field px-1"
                    type="email"
                    name="email"
                    placeholder="john@gmail.com"
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <p className="mntech-primary-clr-text">
                      *Email is required!!!
                    </p>
                  )}
                </div>
                <div className="w-100 mb-3">
                  <label className="fs-5 mb-1">Phone</label>
                  <input
                    className="w-100 input-field px-1"
                    type="tel"
                    name="phone"
                    placeholder="01643616707"
                    {...register("phone", { required: true })}
                  />
                  {errors.phone && (
                    <p className="mntech-primary-clr-text">
                      *Phone number is required!!!
                    </p>
                  )}
                </div>

                <div className="w-100 mb-3">
                  <label className="fs-5 mb-1">Your Location</label>
                  <input
                    className="w-100 input-field px-1"
                    type="text"
                    name="applicant_location"
                    placeholder="Barishal"
                    {...register("applicant_location", { required: true })}
                  />
                  {errors.applicant_location && (
                    <p className="mntech-primary-clr-text">
                      *Location is required!!!
                    </p>
                  )}
                </div>

                {/* <div className="w-100 mb-3">
              <label className="fs-5 mb-1">Apply Position</label>
              <input
                className="w-100 input-field px-1"
                type="text"
                name="apply_position"
                // placeholder="Software Developer"
                defaultValue={jobDetails.job_title}
                {...register("apply_position", { required: true })}
              />
              {errors.apply_position && (
                <p className="mntech-primary-clr-text">
                  *Apply Position is required!!!
                </p>
              )}
            </div> */}
                <div className="w-100 mb-3">
                  <label className="fs-5 mb-1">
                    <span>Apply Position</span>{" "}
                    <span className="fs-6">(Read Only)</span>
                  </label>
                  {/* Read-only input field for displaying jobDetails.job_title */}
                  <input
                    className="w-100 input-field px-1 bg-light-gray"
                    type="text"
                    name="apply_position"
                    defaultValue={jobDetails.job_title}
                    readOnly
                  />
                  {/* {errors.apply_position && (
                    <p className="mntech-primary-clr-text">
                      *Apply Position is required!!!
                    </p>
                  )} */}
                </div>

                <div className="w-100 mb-3">
                  <label className="fs-5 mb-1 d-block">Department</label>
                  <select
                    {...register("department", { required: true })}
                    className="w-100 input-field px-1"
                  >
                    <option value="">Select Department</option>
                    <option value="tech">Software Developer</option>
                    <option value="accounting">Accounting</option>
                    <option value="hr">HR</option>
                  </select>
                  {errors.department && (
                    <p className="mntech-primary-clr-text">
                      *Department is required!!!
                    </p>
                  )}
                </div>
                <div className="w-100 mb-3">
                  <label className="fs-5 mb-1 d-block">Notice Period</label>
                  <select
                    {...register("notice_period", { required: true })}
                    className="w-100 input-field px-1"
                  >
                    <option value="">Select Notice Period</option>
                    <option value="7 days">7 Days</option>
                    <option value="15 days">15 Days</option>
                    <option value="30 days">30 days</option>
                  </select>
                  {errors.notice_period && (
                    <p className="mntech-primary-clr-text">
                      *Notice Period is required!!!
                    </p>
                  )}
                </div>

                <div className="w-100 mb-3">
                  <label className="fs-5 mb-1 d-block">
                    Preferred Job Location
                  </label>
                  <label className="text-danger mb-1 d-block">
                    We are enthusiastic about on-site jobs right now!
                  </label>
                  <select
                    {...register("job_location", { required: true })}
                    className="w-100 input-field px-1"
                  >
                    <option value="">Select a Job Location</option>
                    <option value="on-site">On-site</option>
                    <option value="remote">Remote</option>
                    <option value="hybrid">Hybrid</option>
                  </select>
                  {errors.job_location && (
                    <p className="mntech-primary-clr-text">
                      *Location is required!!!
                    </p>
                  )}
                </div>

                <div className="w-100 mb-3">
                  <label className="fs-5 mb-1">Upload Your Resume/CV:</label>
                  <input
                    className="w-100"
                    type="file"
                    name="resume_cv"
                    accept=".pdf"
                    placeholder="CV/RESUME"
                    {...register("resume_cv", { required: true })}
                  />
                  <p className="mntech-primary-clr-text">
                    *Only .pdf file is allowed! File size must be less than
                    215kb
                  </p>
                  {errors.resume_cv && (
                    <p className="mntech-primary-clr-text">
                      *CV/RESUME is required!!!
                    </p>
                  )}
                </div>

                <div>
                  <label className="text-danger mb-1 d-block">
                    *Every field is required!
                  </label>
                </div>

                <button
                  className="w-100 mb-4 py-2 mntech-btn-fill fs-4"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>

                      <span>Submitting...</span>
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>

                {/* <button className="w-100 mb-4 py-2 mntech-btn-fill fs-4" type="submit">
        Submit
      </button> */}
              </form>
            </>
          )}
        </>
      )}
    </>
  );
};

export default JobApplyForm;
