import React from "react";
import { Card, Container } from "react-bootstrap";
import { SlRocket } from "react-icons/sl";
import { IoBulbOutline } from "react-icons/io5";
import { AiOutlineBarChart } from "react-icons/ai";
import { CiGrid41 } from "react-icons/ci";
import { FiThumbsUp } from "react-icons/fi";
import { RiQuestionnaireLine } from "react-icons/ri";
import FeatureImg from "../../../assets/img/mntechdigital_promote.jpeg";
import "./WePromote.css";

const WePromote = () => {
  return (
    <section className="my-5">
      <div className="text-center" data-aos="fade-down">
        <h1>We Promote</h1>
        <div className="border-b" />
      </div>
      <Container>
        <div className="md-f-col d-flex justify-content-between mx-auto w-100 mt-2 mt-md-3 mt-lg-4">
          <div className="d-flex flex-column" data-aos="fade-right">
            <Card className="cursor-pointer promote-card card-shadow card-hover px-3 border-0">
              <Card.Body className="py-0">
                <h5 className="d-flex gap-4 promote-title justify-content-end align-items-center py-1">
                  <span className="fw-bold fs-4">Digital Transformation</span>
                  <span className="align-self-end">
                    <SlRocket className="fw-normal mntech-primary-clr-text icon" />
                  </span>
                </h5>
              </Card.Body>
            </Card>
            <Card className="cursor-pointer promote-card card-shadow card-hover px-3 border-0">
              <Card.Body className="py-0">
                <h5 className="d-flex gap-4 promote-title justify-content-end align-items-center py-1">
                  <span className="fw-bold fs-4">Inspiring Innovation</span>
                  <span className="align-self-end">
                    <IoBulbOutline className="fw-normal mntech-primary-clr-text icon" />
                  </span>
                </h5>
              </Card.Body>
            </Card>
            <Card className="cursor-pointer promote-card card-shadow card-hover px-3 border-0">
              <Card.Body className="py-0">
                <h5 className="d-flex gap-4 promote-title justify-content-end align-items-center py-1">
                  <span className="fw-bold fs-4">Business Growth</span>
                  <span className="align-self-end">
                    <AiOutlineBarChart className="fw-normal mntech-primary-clr-text icon" />
                  </span>
                </h5>
              </Card.Body>
            </Card>
          </div>
          <div className="intro-mobile-img mt-3" data-aos="fade-up">
            <img src={FeatureImg} alt="introMobile" />
          </div>
          <div className="d-flex flex-column" data-aos="fade-left">
            <Card className="cursor-pointer promote-card card-shadow card-hover px-3 border-0">
              <Card.Body className="py-0">
                <h5 className="d-flex flex-row-reverse justify-content-end gap-4 promote-title align-items-center py-1">
                  <span className="fw-bold fs-4">Latest Technology</span>
                  <span className="align-self-end">
                    <CiGrid41 className="fw-normal mntech-primary-clr-text icon" />
                  </span>
                </h5>
              </Card.Body>
            </Card>
            <Card className="cursor-pointer promote-card card-shadow card-hover px-3 border-0">
              <Card.Body className="py-0">
                <h5 className="d-flex flex-row-reverse justify-content-end gap-4 promote-title align-items-center py-1">
                  <span className="fw-bold fs-4">Application Quality</span>
                  <span className="align-self-end">
                    <FiThumbsUp className="fw-normal mntech-primary-clr-text icon" />
                  </span>
                </h5>
              </Card.Body>
            </Card>
            <Card className="cursor-pointer promote-card card-shadow card-hover px-3 border-0">
              <Card.Body className="py-0">
                <h5 className="d-flex flex-row-reverse justify-content-end gap-4 promote-title align-items-center py-1">
                  <span className="fw-bold fs-4">24/7 Support</span>
                  <span className="align-self-end">
                    <RiQuestionnaireLine className="fw-normal mntech-primary-clr-text icon" />
                  </span>
                </h5>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WePromote;
