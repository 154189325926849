import React from "react";
import "./GoogleMap.css";

const GoogleMap = () => {
  return (
    <>
      <div className="GoogleMap">
        <object
          style={{ border: "0", height: "100%", width: "100%" }}
          title="location"
          data="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d920.2788196555124!2d90.35235542915754!3d22.68675213641036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x37553594d3e577bf%3A0xce2cba69a27bf6c!2sMNTECH%20DIGITAL!5e0!3m2!1sen!2sbd!4v1665662935608!5m2!1sen!2sbd"
        ></object>
      </div>
    </>
  );
};

export default GoogleMap;
