import React from "react";
import { Container } from "react-bootstrap";
import GoogleMap from "./GoogleMap/GoogleMap";
import ContactUsForm from "../../components/ContactUs/ContactUsForm";
import "./ContactUs.css";
import ContactAdressInfo from "../../components/ContactUs/ContactAdressInfo";
import PageHelmet from "../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../utils/helmetContent";

const ContactUs = () => {
  return (
    <section id="contact" className="pt-5 bg-light-gray">
      <PageHelmet
        title={helmetContent?.contactUs?.title}
        description={helmetContent?.contactUs?.desc}
      />
      <div data-aos="fade-down">
        <h2 className="text-center">Office Details</h2>
        <div className="border-b" />
      </div>
      <Container className="mt-5 px-4">
        <div className="md-f-col d-flex justify-content-center w-100 gap-5">
          <ContactAdressInfo />
          <div className="md-w-100" data-aos="fade-left">
            <GoogleMap />
          </div>
        </div>
        <ContactUsForm />
      </Container>
    </section>
  );
};

export default ContactUs;
