import React from "react";
import { Container } from "react-bootstrap";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import SpecificIndBanner from "../../../components/shared/SpecificIndustries/SpecificIndBanner";

import HEALTHCAREBANNER from "../../../assets/img/health_care_banner.png";
import IndSoftwareMarketing from "../../../components/shared/SpecificIndustries/IndSoftwareMarketing";

import EHR from "../../../assets/mntechdigital-icon/health_care/ehr.png";
import PMS from "../../../assets/mntechdigital-icon/health_care/pms.png";
import TS from "../../../assets/mntechdigital-icon/health_care/ts.png";
import MS from "../../../assets/mntechdigital-icon/health_care/ms.png";
import HIE from "../../../assets/mntechdigital-icon/health_care/hie.png";
import PES from "../../../assets/mntechdigital-icon/health_care/pes.png";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const HealthCare = () => {
  // banner description for health care industry
  const bannerDesc = {
    id: 2011,
    indName: "Health Care",
    indBannerDesc: `Healthcare software refers to the various applications 
    and platforms that are used to manage and deliver healthcare services. 
    Healthcare software can be used by healthcare providers, patients, and other 
    stakeholders to improve the quality of care, increase efficiency, and enhance communication. 
    `,
  };

  // industries
  const specificInd = [
    {
      id: 111222,
      indName: "Electronic Health Records (EHRs)",
      indDesc: `EHRs are digital versions of patient medical
      records.They allow healthcare providers to
      easily accesspatient information, including
      medical history, lab results, and medication
      information.
      `,
      indImg: EHR,
    },
    {
      id: 111223,
      indName: "Practice Management Software",
      indDesc: `Practice management software is used to 
      manage the day-to-day operations of a 
      healthcare practice, including scheduling 
      appointments, managing billing and payments,
      and generating reports.
      `,
      indImg: PMS,
    },
    {
      id: 111224,
      indName: "Telemedicine Software",
      indDesc: `Telemedicine software allows healthcare 
      providers to conduct virtual consultations
       with patients, using video conferencing 
      technology. This can help improve access
      to care, especially in rural or remote areas.
      
      `,
      indImg: TS,
    },
    {
      id: 111225,
      indName: "Medical Imaging Software",
      indDesc: `Medical imaging software is used to store,
      manage, and analyze medical images, such
       as X-rays, CT scans, and MRIs. This software
      can help healthcare providersmake more
      accurate diagnoses and developmore 
      effective treatment plans.
      
      `,
      indImg: MS,
    },
    {
      id: 111226,
      indName: "Health Information Exchange ",
      indDesc: `HIE software allows different healthcare
      providers to share patient information securely
      and efficiently.This can help improve patient 
      outcomes by ensuring that all providers have 
      access to the same information.
      
      `,
      indImg: HIE,
    },
    {
      id: 111227,
      indName: "Patient Engagement Software",
      indDesc: `Patient engagement software is used to engage 
      patients in their own care, through tools such as 
      patient portals, mobile apps, and automated 
      messaging. This can help improve patient 
      satisfaction and adherence to treatment plans.
      `,
      indImg: PES,
    },
  ];

  return (
    <section className="mb-5">
      <PageHelmet
        title={helmetContent?.industry?.healthCare?.title}
        description={helmetContent?.industry?.healthCare?.desc}
      />
      <SpecificIndBanner INDBANNER={HEALTHCAREBANNER} bannerDesc={bannerDesc} />
      <Container>
        <IndSoftwareMarketing
          specificInd={specificInd}
          indName={bannerDesc?.indName}
        />
        <LetUsHelp  />
      </Container>
    </section>
  );
};

export default HealthCare;
