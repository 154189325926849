import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import axios from "axios";

const JoinAsPartnerForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleJoinPartnerForm = async (data) => {
    // console.log(data);

    try {
      // Send HTTP POST request to server using Axios
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/joinAsPartner`,
        data
      );
      // console.log(response);

      if (response.data.status) {
        toast.success(`${response?.data?.message}`, {
          style: {
            borderRadius: "10px",
            background: "#222",
            color: "#fff",
          },
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to send partnership request! Please try again.", {
        style: {
          borderRadius: "10px",
          background: "#222",
          color: "#fff",
        },
      });
    }
    reset();
  };

  return (
    <form
      onSubmit={handleSubmit(handleJoinPartnerForm)}
      className="join-partner-form mx-auto mt-6 card p-5 shadow border-0"
    >
      <div className="text-center mb-3">
        <h2>SEND PARTNERSHIP REQUEST</h2>
      </div>
      <div className="w-100 mb-3">
        <input
          className="w-100 input-field px-1"
          type="text"
          name="name"
          placeholder="Name"
          {...register("name", { required: true })}
        />
        {errors.name && (
          <p className="mntech-primary-clr-text">*This field is required!!!</p>
        )}
      </div>
      <div className="w-100 mb-3">
        <input
          className="w-100 input-field px-1"
          type="email"
          name="email"
          placeholder="Email"
          {...register("email", { required: true })}
        />
        {errors.email && (
          <p className="mntech-primary-clr-text">*This field is required!!!</p>
        )}
      </div>
      <div className="w-100 mb-3">
        <input
          className="w-100 input-field px-1"
          type="tel"
          name="phone"
          placeholder="Phone"
          {...register("phone", { required: true })}
        />
        {errors.phone && (
          <p className="mntech-primary-clr-text">*This field is required!!!</p>
        )}
      </div>
      <div className="w-100 mb-3">
        <input
          className="w-100 input-field px-1"
          type="text"
          name="country"
          placeholder="Country"
          {...register("country", { required: true })}
        />
        {errors.country && (
          <p className="mntech-primary-clr-text">*This field is required!!!</p>
        )}
      </div>
      <div className="w-100 mb-3">
        <textarea
          className="w-100 input-field h-partner-50 px-1"
          name="message"
          placeholder="Message"
          {...register("message", { required: true })}
        />
        {errors.message && (
          <p className="mntech-primary-clr-text">*This field is required!!!</p>
        )}
      </div>
      <button className="w-100 mb-4 py-2 mntech-btn-fill fs-4" type="submit">
        Submit
      </button>
    </form>
  );
};

export default JoinAsPartnerForm;
