import React from "react";
import BecomePartner from "../../components/Home/BecomePartner/BecomePartner";
import OurPartners from "../../components/Home/BecomePartner/OurPartners/OurPartners";
import HomeBanner from "../../components/Home/HomeBanner/HomeBanner";
import IndustriesWeCover from "../../components/Home/IndustriesWeCover/IndustriesWeCover";
import MissionAndVission from "../../components/Home/MissionAndVission/MissionAndVission";
import WePromote from "../../components/Home/WePromote/WePromote";
import PageHelmet from "../../components/shared/PageHelmet/PageHelmet";
// import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner";

const Home = () => {
  return (
    <>
      <PageHelmet
        title="Home"
        description="MNTECH DIGITAL is a software company, where we promote Innovation, Business growth, Latest technology, web base solution, software application and mobile app. we have developed a range of software solutions that are designed to be both cost-effective and highly efficient."
      />
      <HomeBanner />
      <IndustriesWeCover />
      <OurPartners />
      <MissionAndVission />
      <WePromote />
      <BecomePartner />
    </>
  );
};

export default Home;
