import { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const RequireAdmin = () => {
  const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleAuthorizedAdmin = () => {
    handleShowModal();
  };

  const onSubmitModal = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    try {
      if (email && username && password && ipAddress) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/auth`,
          {
            username,
            email,
            password,
            ipAddress,
          }
        );
        // console.log(response);
        const { token } = response.data;
        // Redirect to the admin dashboard
        // window.location.href = '/admin';
        if (token) {
          localStorage.setItem("mntechAuthToken", token);
          return navigate("/admin/protected/content");
        }

        handleCloseModal();
      }
    } catch (error) {
      setErrorMessage(error.response.data.error);
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  return (
    <>
      <div className="m-h-100 d-flex d-flex-center-both">
        <button onClick={handleAuthorizedAdmin} className="mntech-btn-fill">
          Authorize Admin
        </button>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} className="mt-6">
        <Modal.Header closeButton>
          <Modal.Title>Admin Auth</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmitModal}>
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              {username === "" && (
                <span className="text-danger">Username is required</span>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {email === "" && (
                <span className="text-danger">Email is required</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {password === "" && (
                <span className="text-danger">Password is required</span>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>IP Address</Form.Label>
              <Form.Control
                type="text"
                name="ipAddress"
                value={ipAddress}
                onChange={(e) => setIpAddress(e.target.value)}
                required
              />
              {ipAddress === "" && (
                <span className="text-danger">IP Address is required</span>
              )}
            </Form.Group>
            <button className="mntech-btn-fill w-100" type="submit">
              Submit
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RequireAdmin;
