import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GrFacebookOption } from "react-icons/gr";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

import TEAM1 from "../../../assets/img/team-1.jpeg";

import "./ManagementProfile.css";
import PageHelmet from "../../shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const ManagementProfile = () => {
  const managementMembers = [
    {
      id: 88800111,
      memName: "R.A Rezwan",
      memPos: "Consultant,Solution",
    },
    {
      id: 88800112,
      memName: "S.Siam ",
      memPos: "Consultant, App Solution",
    },
    {
      id: 88800113,
      memName: "N.I Mosihul",
      memPos: "Consultant,Solution",
    },
    {
      id: 88800114,
      memName: "I.R Riad",
      memPos: "Consultant,Digital Marketing",
    },
    {
      id: 88800115,
      memName: "E. Edward",
      memPos: "Consultant,Design",
    },
    {
      id: 88800116,
      memName: "T.H Onna",
      memPos: "Consultant,Data Entry",
    },
    {
      id: 88800117,
      memName: "N. Sameha",
      memPos: "Consultant",
    },
  ];

  return (
    <section id="about">
      <PageHelmet
        title={helmetContent?.about?.profile?.title}
        description={helmetContent?.about?.profile?.desc}
      />
      <div data-aos="fade-down">
        <h2 className="text-center">Management Profile</h2>
        <div className="border-b" />
      </div>
      <Container>
        <div className="g-4 my-5">
          <>
            <div
              className="card mb-3 mem-card-shadow card-hover border-0 card-w-h mx-auto"
              data-aos="fade-up"
            >
              <div className="row g-0">
                <div className="col-md-4">
                  <img
                    src={TEAM1}
                    className="img-fluid rounded-start h-100 w-100"
                    alt="mosrur"
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title mb-0">MOSRUR RAKAYET</h5>
                    <span className="border-b-gray card-title mt-0 mntech-secondary-clr-text pos-text">
                      Ceo & co-founder
                    </span>
                    <p className="quote-text mt-3">
                      MNTECH DIGITAL believes in a transformative impact on
                      businesses, enable them to innovate, grow, and adapt to
                      changing market conditions through Technology.
                    </p>
                    <p className="card-text">
                      <a
                        href="https://www.facebook.com/mosrursunny"
                        className="facebook"
                        title="ceofacebook"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GrFacebookOption className="fs-3" />
                      </a>
                      <a
                        href="https://www.facebook.com/mosrursunny"
                        className="twitter"
                        title="ceotwitter"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AiOutlineTwitter className="fs-3" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/mosrur-rakayet-sunny-15973b173/"
                        className="linkedin"
                        title="ceolinkedin"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaLinkedinIn className="fs-3" />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Row
              xs={1}
              sm={2}
              md={2}
              lg={4}
              className="my-5 mx-auto d-flex d-flex-center-both"
            >
              <>
                {managementMembers.map((member) => (
                  <Col
                    key={member?.id}
                    className="mem-card mb-5"
                    data-aos={member?.id % 2 ? "fade-left" : "fade-right"}
                  >
                    <div className="mem-card-shadow card-hover border-0 mem-card d-flex d-flex-center-both">
                      <div>
                        <h5 className="mem-card-title mb-0">
                          {member?.memName}
                        </h5>
                        <h5 className="mt-2 mntech-secondary-clr-text pos-text">
                          {member?.memPos}
                        </h5>
                      </div>
                    </div>
                  </Col>
                ))}
              </>
            </Row>
          </>
        </div>
      </Container>
    </section>
  );
};

export default ManagementProfile;
