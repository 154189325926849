import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

const AdminJobPosting = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isLoading },
    reset,
  } = useForm();

  const handleAdminJobPostingForm = async (data) => {
    const {
      deadline,
      job_description,
      job_location,
      job_title,
      qualification,
      vacancy_quantity,
      job_type,
    } = data;

    const date = new Date(deadline);
    const milliseconds = date.getTime();

    const jobPostData = {
      job_title,
      vacancy_quantity,
      qualification,
      job_description,
      job_location,
      deadline: milliseconds,
      job_type,
    };

    const token = localStorage.getItem("mntechAuthToken");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/jobposts`,
        jobPostData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.status) {
        toast.success(response?.data?.message, {
          style: {
            borderRadius: "10px",
            background: "#222",
            color: "#fff",
          },
        });
        reset();
      }
    } catch (error) {
      toast.error(error?.message, {
        style: {
          borderRadius: "10px",
          background: "#222",
          color: "#fff",
        },
      });
    }
  };

  return (
    <section className="my-4">
      <form
        onSubmit={handleSubmit(handleAdminJobPostingForm)}
        className="join-partner-form mx-auto mt-6 card p-5 shadow border-0"
      >
        <div className="text-center mb-3">
          <h1>POST A JOB</h1>
        </div>
        <div className="w-100 mb-3">
          <label className="fs-5 mb-1">Job Title</label>
          <input
            className="w-100 input-field px-1"
            type="text"
            name="job_title"
            placeholder="Software Developer"
            {...register("job_title", { required: true })}
          />
          {errors.job_title && (
            <p className="mntech-primary-clr-text">*Job Title is required!!!</p>
          )}
        </div>

        <div className="w-100 mb-3">
          <label className="fs-5 mb-1">Job Type</label>
          <input
            className="w-100 input-field px-1"
            type="text"
            name="job_type"
            placeholder="Full Time"
            {...register("job_type", { required: true })}
          />
          {errors.job_type && (
            <p className="mntech-primary-clr-text">*Job Type is required!!!</p>
          )}
        </div>

        <div className="w-100 mb-3">
          <label className="fs-5 mb-1">Vacancy Quantity</label>
          <input
            className="w-100 input-field px-1"
            type="text"
            name="vacancy_quantity"
            placeholder="3"
            {...register("vacancy_quantity", { required: true })}
          />
          {errors.vacancy_quantity && (
            <p className="mntech-primary-clr-text">
              *Vacancy Quantity is required!!!
            </p>
          )}
        </div>

        <div className="w-100 mb-3">
          <label className="fs-5 mb-1">Job Description</label>
          <textarea
            className="w-100 h-text-area input-field px-1"
            type="tel"
            name="job_description"
            placeholder="Write job description..."
            {...register("job_description", { required: true })}
          ></textarea>
          {errors.job_description && (
            <p className="mntech-primary-clr-text">
              *Job description is required!!!
            </p>
          )}
        </div>

        <div className="w-100 mb-3">
          <label className="fs-5 mb-1">Qualification</label>
          <textarea
            className="w-100 h-text-area input-field px-1"
            type="text"
            name="qualification"
            placeholder="Write qualifications for this job..."
            {...register("qualification", { required: true })}
          ></textarea>
          {errors.qualification && (
            <p className="mntech-primary-clr-text">
              *Qualification is required!!!
            </p>
          )}
        </div>

        <div className="w-100 mb-3">
          <label className="fs-5 mb-1 d-block">Job Location</label>
          <select
            {...register("job_location", { required: true })}
            className="w-100 input-field px-1"
          >
            <option value="">Select a Job Location</option>
            <option value="On-site">On-site</option>
            <option value="Remote">Remote</option>
            <option value="Hybrid">Hybrid</option>
          </select>
          {errors.job_location && (
            <p className="mntech-primary-clr-text">
              *Job Location is required!!!
            </p>
          )}
        </div>

        <div className="w-100 mb-3">
          <label className="fs-5 mb-1">Deadline</label>
          <input
            className="w-100 input-field px-1"
            type="date"
            name="deadline"
            {...register("deadline", { required: true })}
          />
          {errors.deadline && (
            <p className="mntech-primary-clr-text">*Deadline is required!!!</p>
          )}
        </div>

        <div>
          <label className="text-danger mb-1 d-block">
            *Every field is required!
          </label>
        </div>

        {/* <button
          className="w-100 mb-4 py-2 mntech-btn-fill fs-4"
          type="submit"
          disabled={isLoading ? true : false}
        >
          Submit
        </button> */}
        <button
          className="w-100 mb-4 py-2 mntech-btn-fill fs-4"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              <span>Submitting...</span>
            </>
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </section>
  );
};

export default AdminJobPosting;
