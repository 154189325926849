import React from "react";
import { Container } from "react-bootstrap";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import INDCODING from "../../../assets/img/ind_code.png";
import ServIndustries from "../../../components/shared/SpecificServices/ServIndustries";
import WEBDEVIMG from "../../../assets/img/web_dev.png";
import html5 from "../../../assets/mntechdigital-icon/web/html_5.png";
import js from "../../../assets/mntechdigital-icon/web/js.png";
import react from "../../../assets/mntechdigital-icon/web/react.png";
import mongodb from "../../../assets/mntechdigital-icon/web/mongodb.png";
import nodejs from "../../../assets/mntechdigital-icon/web/nodejs.png";
import php from "../../../assets/mntechdigital-icon/web/php.png";
import mysql from "../../../assets/mntechdigital-icon/web/mysql.png";
import bootstrap from "../../../assets/mntechdigital-icon/web/bootstrap.png";
import SpecificServBanner from "../../../components/shared/SpecificServices/SpecificServBanner";
import SpecificServDetails from "../../../components/shared/SpecificServices/SpecificServDetails";
import SpecificServTechnologies from "../../../components/shared/SpecificServices/SpecificServTechnologies";

import "../../../styles/Style.css";
import ScrollToDown from "../../../components/shared/ScrollToTopButton/ScrollToDown";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const WebDevelopment = () => {
  // website development banner elements
  const bannerElements = {
    id: 100001,
    serviceName: "Website Development",
    serviceDesc: `Website development refers to the process of creating and designing
    websites that can be accessed via the internet.`,
    serviceImg: WEBDEVIMG,
    btnAddress: "#explore",
  };

  // technologies used for web
  const technologies = [
    {
      id: 5000,
      techImg: html5,
      techName: "HTML5",
    },
    {
      id: 5001,
      techImg: js,
      techName: "JavaScript",
    },
    {
      id: 5002,
      techImg: react,
      techName: "ReactJs",
    },
    {
      id: 5003,
      techImg: mongodb,
      techName: "MongoDB",
    },
    {
      id: 5004,
      techImg: nodejs,
      techName: "NodeJs",
    },
    {
      id: 5005,
      techImg: php,
      techName: "Php",
    },
    {
      id: 5006,
      techImg: mysql,
      techName: "MySQL",
    },
    {
      id: 5007,
      techImg: bootstrap,
      techName: "Bootstrap5",
    },
  ];

  // website service details
  const details = {
    firstParagraph: ` It involves different aspects such as website design, website
  programming, web content development, server configuration, network
  security configuration, and database management. Website development
  is crucial for businesses and organizations, as it enables them to
  establish an online presence and interact with customers on a global
  scale.`,
    secondParagraph: `A well-designed website can help businesses to attract potential
  customers, build brand awareness, and increase sales and revenue.
  MNTECH DIGITAL Specialized in creating high-quality, websites that is
  unique for your business. MNTECH DIGITAL helps you to stand out in a
  crowded marketplace, attract more traffic to your site, and ultimately
  drive more leads and revenue.`,
  };

  // industries
  const industries = [
    {
      id: 6000,
      indName: "Telecommunication",
    },
    {
      id: 6001,
      indName: "Real Estate",
    },
    {
      id: 6002,
      indName: "E-commerce",
    },
    {
      id: 6003,
      indName: "Software",
    },
    {
      id: 6004,
      indName: "Education",
    },
    {
      id: 6005,
      indName: "Non Profit",
    },
  ];

  return (
    <section className="mt-5 m-h-100">
      <PageHelmet
        title={helmetContent?.ourServices?.web?.title}
        description={helmetContent?.ourServices?.web?.desc}
      />
      <Container>
        <SpecificServBanner bannerElements={bannerElements} />
        <ScrollToDown btnAddress={bannerElements?.btnAddress} />
        <SpecificServDetails details={details} />
        <SpecificServTechnologies technologies={technologies} />
        <ServIndustries industries={industries} industryImg={INDCODING} />
        <LetUsHelp />
      </Container>
    </section>
  );
};

export default WebDevelopment;
