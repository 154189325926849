import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ERP from "../../../assets/mntechdigital-icon/industries/erp.png";
import HEALTHCARE from "../../../assets/mntechdigital-icon/industries/health_care.png";
import TELCO from "../../../assets/mntechdigital-icon/industries/telco.png";
import REALESTATE from "../../../assets/mntechdigital-icon/industries/real_estate.png";
import ECOM from "../../../assets/mntechdigital-icon/industries/ecom.png";
import SOFT from "../../../assets/mntechdigital-icon/industries/soft.png";
import EDUCATION from "../../../assets/mntechdigital-icon/industries/education.png";
import RETAIL from "../../../assets/mntechdigital-icon/industries/retail.png";
import STARTUP from "../../../assets/mntechdigital-icon/industries/start_up.png";
import NONPROFIT from "../../../assets/mntechdigital-icon/industries/non_profit.png";
import "./IndustriesWeCover.css";
import { Link } from "react-router-dom";

const IndustriesWeCover = () => {
  // industries icons
  const industriesIcons = [
    {
      id: 3000,
      icon: ERP,
      iconName: "ERP",
      address: "/industries/erp",
    },
    {
      id: 3001,
      icon: HEALTHCARE,
      iconName: "Health Care",
      address: "/industries/health-care",
    },
    {
      id: 3002,
      icon: TELCO,
      iconName: "Telco",
      address: "/industries/telecommunication",
    },
    {
      id: 3003,
      icon: REALESTATE,
      iconName: "Real Estate",
      address: "/industries/real-estate",
    },
    {
      id: 3004,
      icon: ECOM,
      iconName: "E-Commerce",
      address: "/industries/e-com",
    },
    {
      id: 3005,
      icon: SOFT,
      iconName: "Software",
      address: "/industries/software",
    },
    {
      id: 3006,
      icon: EDUCATION,
      iconName: "Education",
      address: "/industries/education",
    },
    {
      id: 3007,
      icon: RETAIL,
      iconName: "Retail",
      address: "/industries/retail",
    },
    {
      id: 3008,
      icon: STARTUP,
      iconName: "Start Up",
      address: "/industries/start-up",
    },
    {
      id: 3009,
      icon: NONPROFIT,
      iconName: "Non Profit",
      address: "/industries/non-profit",
    },
  ];

  return (
    <section>
      <div className="text-center" data-aos="fade-down">
        <h1>Industries We Cover</h1>
        <div className="border-b" />
      </div>
      <Container className="mt-5">
        <Row xs={3} md={4} lg={5} className="text-center">
          {industriesIcons?.map((industry) => (
            <Col key={industry?.id} className="mb-5" data-aos="fade-up">
              <Link to={industry?.address} className="no-underline">
                <img
                  className="industries-icon"
                  src={industry?.icon}
                  alt={industry?.iconName}
                />
                <h4 className="mt-3">{industry?.iconName}</h4>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default IndustriesWeCover;
