import React from "react";
import { Container } from "react-bootstrap";
import AllBlogs from "../../../../components/AboutUs/Media/AllBlogs";
import "./Blogs.css";
import PageHelmet from "../../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../../utils/helmetContent";

const Blogs = () => {
  return (
    <Container className="my-5">
      <PageHelmet
        title={helmetContent?.about?.blogs?.title}
        description={helmetContent?.about?.blogs?.desc}
      />
      <AllBlogs />
    </Container>
  );
};

export default Blogs;
