import React, { useEffect, useReducer } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {
  MdKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { Link } from "react-router-dom";
import MNTECHLOGO from "../../../assets/img/mntechdigital_logo_navbar.png";
// import { Link as ScrollLink, animateScroll } from "react-scroll";
import { RxCross1, RxHamburgerMenu } from "react-icons/rx";
import "./NavMenuBar.css";

// initialState for all navbar states
const initialState = {
  scrollPosition: 0,
  navMouseOver: false,
  isServiceOpen: false,
  isIndustriesOpen: false,
  isAboutOpen: false,
  isMobileViewOpen: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SCROLL_POSITION":
      return { ...state, scrollPosition: action.payload };
    case "SET_NAV_MOUSE_OVER":
      return { ...state, navMouseOver: action.payload };
    case "SET_IS_SERVICE_OPEN":
      return {
        ...state,
        isServiceOpen: action.payload,
        isIndustriesOpen: false,
        isAboutOpen: false,
      };
    case "SET_IS_INDUSTRIES_OPEN":
      return {
        ...state,
        isIndustriesOpen: action.payload,
        isServiceOpen: false,
        isAboutOpen: false,
      };
    case "SET_IS_ABOUT_OPEN":
      return {
        ...state,
        isAboutOpen: action.payload,
        isServiceOpen: false,
        isIndustriesOpen: false,
      };
    case "SET_IS_MOBILE_NAV_OPEN":
      return {
        ...state,
        isMobileViewOpen: action.payload,
      };
    default:
      return state;
  }
};

const NavMenuBar = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // get scroll info for navbar shadow start
  function handleScroll() {
    const position = window.pageYOffset;
    dispatch({ type: "SET_SCROLL_POSITION", payload: position });
    // setScrollPosition(position);
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  // get scroll info for navbar shadow end

  // console.log(state);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`bg-white w-100 py-2 nav-font nav-con ${
          state?.scrollPosition > 0 ? "shadow position-fixed top-0" : undefined
        }`}
      >
        <Container>
          <Link to="/" className="nav-link mx-2">
            <img
              className="mntech-logo"
              src={MNTECHLOGO}
              alt="MNTECH DIGITAL LOGO"
            />
          </Link>

          {/* hamburger for medium and small device start */}
          <p
            onClick={() =>
              dispatch({
                type: "SET_IS_MOBILE_NAV_OPEN",
                payload: !state?.isMobileViewOpen,
              })
            }
            className="hamburger-menu d-block d-lg-none"
          >
            {state?.isMobileViewOpen ? (
              <RxCross1 className="w-100 h-100" />
            ) : (
              <RxHamburgerMenu className="w-100 h-100" />
            )}
          </p>

          <Navbar.Collapse>
            {/* Only for large screen navbar starts */}
            <Nav className="ms-auto mt-4 mt-md-4 mt-lg-0">
              <Link to="/" className={`mx-2 nav-menu-link`}>
                Home
              </Link>
              <div
                className="position-relative d-none d-md-none d-lg-block"
                onMouseOver={() =>
                  dispatch({ type: "SET_NAV_MOUSE_OVER", payload: "Services" })
                }
                onMouseLeave={() =>
                  dispatch({ type: "SET_NAV_MOUSE_OVER", payload: false })
                }
              >
                <Link to="/services" className={`mx-2 nav-menu-link`}>
                  <span>Services</span>
                  <span>
                    <MdKeyboardArrowDown className="fs-3" />
                  </span>
                </Link>
                {state?.navMouseOver === "Services" && (
                  <div className="dropdown dropdown-shadow d-flex flex-column position-absolute bg-white">
                    <Link
                      to="/service/web-dev"
                      className="no-underline dropdown-nav-menu"
                    >
                      Website Development
                    </Link>
                    <Link
                      to="/service/mobile-app"
                      className="no-underline dropdown-nav-menu"
                    >
                      Mobile App Development
                    </Link>
                    <Link
                      to="/service/digital-marketing"
                      className="no-underline dropdown-nav-menu"
                    >
                      Digital Marketing
                    </Link>
                    <Link
                      to="service/ui-ux"
                      className="no-underline dropdown-nav-menu"
                    >
                      UI/UX
                    </Link>
                    <Link
                      to="service/data-entry"
                      className="no-underline dropdown-nav-menu"
                    >
                      Data Entry
                    </Link>
                    <Link
                      to="service/qa"
                      className="no-underline dropdown-nav-menu"
                    >
                      Quality Assurance
                    </Link>
                  </div>
                )}
              </div>

              <div
                className="position-relative d-none d-md-none d-lg-block"
                onMouseOver={() =>
                  dispatch({
                    type: "SET_NAV_MOUSE_OVER",
                    payload: "Industries",
                  })
                }
                onMouseLeave={() =>
                  dispatch({ type: "SET_NAV_MOUSE_OVER", payload: false })
                }
              >
                <Link to="/industries" className={`mx-2 nav-menu-link`}>
                  <span>Industries</span>
                  <span>
                    <MdKeyboardArrowDown className="fs-3" />
                  </span>
                </Link>

                {state?.navMouseOver === "Industries" && (
                  <div className="dropdown dropdown-shadow d-flex flex-column position-absolute bg-white">
                    <Link
                      to="/industries/erp"
                      className="no-underline dropdown-nav-menu"
                    >
                      ERP
                    </Link>
                    <Link
                      to="/industries/health-care"
                      className="no-underline dropdown-nav-menu"
                    >
                      Health Care
                    </Link>
                    <Link
                      to="/industries/telecommunication"
                      className="no-underline dropdown-nav-menu"
                    >
                      Telecommunication
                    </Link>
                    <Link
                      to="/industries/real-estate"
                      className="no-underline dropdown-nav-menu"
                    >
                      Real Estate
                    </Link>
                    <Link
                      to="/industries/e-com"
                      className="no-underline dropdown-nav-menu"
                    >
                      E-Commerce
                    </Link>
                    <Link
                      to="/industries/software"
                      className="no-underline dropdown-nav-menu"
                    >
                      Software
                    </Link>
                    <Link
                      to="/industries/education"
                      className="no-underline dropdown-nav-menu"
                    >
                      Education
                    </Link>
                    <Link
                      to="/industries/retail"
                      className="no-underline dropdown-nav-menu"
                    >
                      Retail
                    </Link>
                    <Link
                      to="/industries/start-up"
                      className="no-underline dropdown-nav-menu"
                    >
                      Start Up
                    </Link>
                    <Link
                      to="/industries/non-profit"
                      className="no-underline dropdown-nav-menu"
                    >
                      Non Profit
                    </Link>
                  </div>
                )}
              </div>
              <div
                className="position-relative d-none d-md-none d-lg-block"
                onMouseOver={() =>
                  dispatch({
                    type: "SET_NAV_MOUSE_OVER",
                    payload: "Aboutus",
                  })
                }
                onMouseLeave={() =>
                  dispatch({ type: "SET_NAV_MOUSE_OVER", payload: false })
                }
              >
                <Link className={`mx-2 nav-menu-link`}>
                  <span>About Us</span>
                  <span>
                    <MdKeyboardArrowDown className="fs-3" />
                  </span>
                </Link>

                {state?.navMouseOver === "Aboutus" && (
                  <div className="dropdown dropdown-shadow d-flex flex-column position-absolute bg-white">
                    <Link
                      to="/about/our-story"
                      className="no-underline dropdown-nav-menu"
                    >
                      Our Story
                    </Link>
                    <Link
                      to="/about/meet-us"
                      className="no-underline dropdown-nav-menu"
                    >
                      Meet us
                    </Link>
                    <Link
                      to="/about/blogs"
                      className="no-underline dropdown-nav-menu"
                    >
                      Blog
                    </Link>
                    <Link
                      to="/about/career"
                      className="no-underline dropdown-nav-menu"
                    >
                      Career
                    </Link>
                  </div>
                )}
              </div>

              <Link to="/contact-us" className={`mx-2 nav-menu-link`}>
                Contact Us
              </Link>
            </Nav>
            {/* Only for large screen navbar ends */}
          </Navbar.Collapse>
        </Container>
        {state?.isMobileViewOpen && (
          <div className="position-absolute top-100 d-lg-none bg-white w-100 m-h-100 pb-3">
            {/* Medium, Small and extra small screen navmenu part starts */}
            <Container>
              <div className="h-100">
                <Link
                  onClick={() =>
                    dispatch({
                      type: "SET_IS_MOBILE_NAV_OPEN",
                      payload: !state?.isMobileViewOpen,
                    })
                  }
                  to="/"
                  className={`mx-2 nav-menu-link mt-4`}
                >
                  Home
                </Link>

                <div className="d-lg-none pe-2 mt-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <Link
                      to="/services"
                      className={`mx-2 nav-menu-link`}
                      onClick={() =>
                        dispatch({
                          type: "SET_IS_MOBILE_NAV_OPEN",
                          payload: !state?.isMobileViewOpen,
                        })
                      }
                    >
                      Services
                    </Link>
                    <p
                      onClick={() =>
                        dispatch({
                          type: "SET_IS_SERVICE_OPEN",
                          payload: !state?.isServiceOpen,
                        })
                      }
                      className="w-h-icon mt-3"
                    >
                      <MdKeyboardArrowDown className="w-100 h-100 border-mntech rounded-circle p-1 mt-1" />
                    </p>
                  </div>
                  {state?.isServiceOpen && (
                    <div
                      onClick={() =>
                        dispatch({
                          type: "SET_IS_MOBILE_NAV_OPEN",
                          payload: !state?.isMobileViewOpen,
                        })
                      }
                      className="dropdown d-flex flex-column gap-4 px-4 bg-white w-100"
                    >
                      <Link
                        to="/service/web-dev"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Website Development</span>
                      </Link>
                      <Link
                        to="/service/mobile-app"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Mobile App Development</span>
                      </Link>
                      <Link
                        to="/service/digital-marketing"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Digital Marketing</span>
                      </Link>
                      <Link
                        to="/service/ui-ux"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>UI/UX</span>
                      </Link>
                      <Link
                        to="/service/data-entry"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Data Entry</span>
                      </Link>
                      <Link
                        to="/service/qa"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Quality Assurance</span>
                      </Link>
                    </div>
                  )}
                </div>

                <div className="d-lg-none pe-2 mt-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <Link
                      to="/industries"
                      className={`mx-2 nav-menu-link`}
                      onClick={() =>
                        dispatch({
                          type: "SET_IS_MOBILE_NAV_OPEN",
                          payload: !state?.isMobileViewOpen,
                        })
                      }
                    >
                      Industries
                    </Link>
                    <p
                      onClick={() =>
                        dispatch({
                          type: "SET_IS_INDUSTRIES_OPEN",
                          payload: !state?.isIndustriesOpen,
                        })
                      }
                      className="w-h-icon"
                    >
                      <MdKeyboardArrowDown className="w-100 h-100 border-mntech rounded-circle p-1 mt-1" />
                    </p>
                  </div>

                  {state?.isIndustriesOpen && (
                    <div
                      onClick={() =>
                        dispatch({
                          type: "SET_IS_MOBILE_NAV_OPEN",
                          payload: !state?.isMobileViewOpen,
                        })
                      }
                      className="dropdown d-flex flex-column gap-4 px-4 bg-white"
                    >
                      <Link
                        to="/industries/erp"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>ERP</span>
                      </Link>
                      <Link
                        to="/industries/health-care"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Health Care</span>
                      </Link>
                      <Link
                        to="/industries/telecommunication"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Telecommunication</span>
                      </Link>
                      <Link
                        to="/industries/real-estate"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Real Estate</span>
                      </Link>
                      <Link
                        to="/industries/e-com"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>E-Commerce</span>
                      </Link>
                      <Link
                        to="/industries/software"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Software</span>
                      </Link>
                      <Link
                        to="/industries/education"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Education</span>
                      </Link>
                      <Link
                        to="/industries/retail"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Retail</span>
                      </Link>
                      <Link
                        to="/industries/start-up"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Start Up</span>
                      </Link>
                      <Link
                        to="/industries/non-profit"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Non Profit</span>
                      </Link>
                    </div>
                  )}
                </div>

                <div className="d-lg-none pe-2 mt-3">
                  <div
                    onClick={() =>
                      dispatch({
                        type: "SET_IS_ABOUT_OPEN",
                        payload: !state?.isAboutOpen,
                      })
                    }
                    className="d-flex align-items-center justify-content-between"
                  >
                    <p className={`mx-2 nav-menu-link`}>About</p>
                    <p className="w-h-icon">
                      <MdKeyboardArrowDown className="w-100 h-100 border-mntech rounded-circle p-1 mt-1" />
                    </p>
                  </div>

                  {state?.isAboutOpen && (
                    <div
                      onClick={() =>
                        dispatch({
                          type: "SET_IS_MOBILE_NAV_OPEN",
                          payload: !state?.isMobileViewOpen,
                        })
                      }
                      className="dropdown d-flex flex-column gap-4 px-4 bg-white"
                    >
                      <Link
                        to="/about/our-story"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Our Story</span>
                      </Link>
                      <Link
                        to="/about/meet-us"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Meet us</span>
                      </Link>
                      <Link
                        to="/about/blogs"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Blog</span>
                      </Link>
                      <Link
                        to="/about/career"
                        className="no-underline dropdown-nav-menu d-flex align-items-center"
                      >
                        <MdOutlineKeyboardArrowRight className="fs-2 text-danger" />
                        <span>Career</span>
                      </Link>
                    </div>
                  )}
                </div>

                <Link
                  onClick={() =>
                    dispatch({
                      type: "SET_IS_MOBILE_NAV_OPEN",
                      payload: !state?.isMobileViewOpen,
                    })
                  }
                  to="/contact-us"
                  className={`mx-2 nav-menu-link mt-4`}
                >
                  Contact Us
                </Link>
              </div>
            </Container>
            {/* Medium and Small screen navmenu part ends */}
          </div>
        )}
      </Navbar>
    </>
  );
};

export default NavMenuBar;
