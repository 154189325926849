import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BLOG1 from "../../../assets/img/blog_1.png";
import ANON from "../../../assets/mntechdigital-icon/blog/anonymous.png";
import CAL from "../../../assets/mntechdigital-icon/blog/calendar.png";

const AllBlogs = () => {
  // blogs
  const blogs = [
    {
      id: 5670,
      blogTitle: `Know Machine learning and artificial intelligence with Mntech Digital`,
      blogOwner: `mntech digital`,
      blogPostDate: "Jan 2, 2023",
      blogImg: BLOG1,
    }
  ];

  return (
    <section className="d-flex justify-content-center">
      <Row>
        {blogs?.map((blog, idx) => (
          <Col
            key={blog.id}
            className="mb-3"
            data-aos={!(idx % 2) ? "fade-right" : "fade-left"}
          >
            <Card className="h-100 w-100">
              <Card.Img variant="top" src={blog?.blogImg} />
              <Card.Body>
                <Card.Title>{blog?.blogTitle}</Card.Title>
                <div className="d-flex justify-content-around my-3">
                  <div className="d-flex align-items-center gap-2">
                    <span>
                      <img className="blog-icon" src={ANON} alt="icon" />
                    </span>
                    <span className="mntech-secondary-clr-text">
                      {blog?.blogOwner}
                    </span>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <span>
                      <img className="blog-icon" src={CAL} alt="icon" />
                    </span>
                    <span className="mntech-secondary-clr-text">
                      {blog?.blogPostDate}
                    </span>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Link
                    to={`/about/blog/${blog?.id}`}
                    className="mntech-primary-clr-text mntech-primary-clr-text-hover"
                    
                  >
                    Read More
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default AllBlogs;
