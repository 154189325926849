import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-hot-toast";

const UpdateJobModal = ({
  show,
  handleClose,
  token,
  jobId,
  jobTitle,
  vacancyQuantity,
  jobDeadline,
  jobDescription,
  jobQualification,
  jobLocation,
  jobType,
  setIsUpdateJob
  
}) => {
  const { register, handleSubmit, reset } = useForm();

  const handleUpdateJob = async (data) => {
    const {
      job_title,
      job_type,
      vacancy_quantity,
      job_description,
      qualification,
      job_location,
      deadline,
    } = data;

    const date = new Date(deadline);
    const milliseconds = date.getTime();

    const jobPostData = {
      job_title: job_title ? job_title : jobTitle,
      job_type: job_type ? job_type : jobType,
      vacancy_quantity: vacancy_quantity ? vacancy_quantity : vacancyQuantity,
      job_description: job_description ? job_description : jobDescription,
      qualification: qualification ? qualification : jobQualification,
      job_location: job_location ? job_location : jobLocation,
      deadline: deadline ? milliseconds : jobDeadline,
    };

    try {
      // Perform the update request using Axios or your preferred HTTP library
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/jobposts/${jobId}`,
        jobPostData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Handle the response accordingly
      if (response.data.status) {
        toast.success(response.data.message, {
          style: {
            borderRadius: "10px",
            background: "#222",
            color: "#fff",
          },
        });
        setIsUpdateJob(true);
        reset();
        
        // Close the modal
        handleClose();
      }
    } catch (error) {
      toast.error(error?.message, {
        style: {
          borderRadius: "10px",
          background: "#222",
          color: "#fff",
        },
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="mt-6 mb-5">
      <Modal.Header closeButton>
        <Modal.Title>Update Job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handleUpdateJob)} className="pb-5">
          <div className="mb-3">
            <label className="form-label">Job Title</label>
            <input
              type="text"
              className="form-control"
              {...register("job_title")}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Job Type</label>
            <input
              type="text"
              className="form-control"
              {...register("job_type")}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Vacancy Quantity</label>
            <input
              type="text"
              className="form-control"
              {...register("vacancy_quantity")}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Job Description</label>
            <textarea
              className="form-control"
              {...register("job_description")}
            ></textarea>
          </div>

          <div className="mb-3">
            <label className="form-label">Qualification</label>
            <textarea
              className="form-control"
              {...register("qualification")}
            ></textarea>
          </div>

          <div className="mb-3">
            <label className="form-label">Job Location</label>
            <select className="form-control" {...register("job_location")}>
              <option value="">Select a Job Location</option>
              <option value="On-site">On-site</option>
              <option value="Remote">Remote</option>
              <option value="Hybrid">Hybrid</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="form-label">Deadline</label>
            <input
              type="date"
              className="form-control"
              {...register("deadline")}
            />
          </div>

          <Button variant="secondary" onClick={handleClose} className="me-4">
            Cancel
          </Button>
          <Button type="submit" variant="info">
            Update
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateJobModal;
