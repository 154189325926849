import React from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import LoadingSpinner from "../../../components/shared/LoadingSpinner/LoadingSpinner";
import useJobDetails from "../../../hooks/getSingleJob";

const SpecificJobDetails = () => {
  // const [jobDetails, setJobDetails] = useState({});
  // const [isJobDetailsLoading, setIsJobDetailsLoading] = useState(false);
  const location = useLocation();
  // console.log(location.pathname.split("/").pop())
  const id = location.pathname.split("/").pop();
  const { jobDetails, isJobDetailsLoading } = useJobDetails(id);

  // useEffect(() => {
  //   setIsJobDetailsLoading(true);
  //   // Extract the job ID from the URL
  //   const id = window.location.pathname.split("/").pop();

  //   const getOneJob = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/jobposts/${id}`
  //       );
  //       // console.log(response);
  //       setJobDetails(response?.data?.results[0]);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   getOneJob();
  //   setIsJobDetailsLoading(false);
  // }, []);

  // console.log(jobDetails);
  const milliseconds = jobDetails?.deadline;

  const date = new Date(milliseconds);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  const formattedDate = `${day}/${month}/${year}`;

  // Function to split the text using the regex pattern and create a list
  const createNumberedList = (text) => {
    const regexPattern = /\b(?:[1-9]|1[0-5])\./g;
    const listItems = text?.split(regexPattern);
    // return listItems;
    return listItems?.filter((item) => item.trim() !== "");
  };

  // const text = jobDetails.job_description;
  // const regex = /\b(?:[1-9]|1[0-5])\./g;
  // const matches = text?.split(regex);
  // console.log(matches);

  return (
    <>
      {isJobDetailsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Container className="mt-5 m-h-100">
            <div className="px-5">
              <div className="d-flex justify-content-between">
                <h4>{jobDetails?.job_title}</h4>
                <h5>
                  <span className="fw-semibold">Deadline: </span>
                  <span className="mntech-primary-clr-text fs-5">
                    {formattedDate}
                  </span>
                </h5>
              </div>
              <div className="mt-4">
                <div className="w-100 d-flex-center-both py-3">
                  <h4 className="w-25 d-flex justify-content-between fw-semibold">
                    <span>Job Title</span>
                    <span>:</span>
                  </h4>
                  <h4 className="w-75 px-3 fw-normal">
                    {jobDetails?.job_title}
                  </h4>
                </div>
                <div className="w-100 d-flex-center-both py-3">
                  <h4 className="w-25 d-flex justify-content-between fw-semibold">
                    <span>Vacancy Quantity</span>
                    <span>:</span>
                  </h4>
                  <h4 className="w-75 px-3 fw-normal">
                    {jobDetails?.vacancy_quantity}
                  </h4>
                </div>
                {/* <div className="w-100 d-flex py-3">
                  <h4 className="w-25 d-flex justify-content-between fw-semibold">
                    <span>Job Description</span>
                    <span>:</span>
                  </h4>
                  <h4 className="w-75 h-auto px-3 fw-normal lh-base">
                    {jobDetails?.job_description}
                  </h4>
                </div>
                <div className="w-100 d-flex py-3">
                  <h4 className="w-25 d-flex justify-content-between fw-semibold">
                    <span>Qualification</span>
                    <span>:</span>
                  </h4>
                  <h4 className="w-75 px-3 fw-normal lh-base">
                    {jobDetails?.qualification}
                  </h4>
                </div> */}
                <div className="w-100 d-flex py-3">
                  <h4 className="w-25 d-flex justify-content-between fw-semibold">
                    <span>Job Requirments</span>
                    <span>:</span>
                  </h4>
                  <div className="w-75 px-3 fw-normal lh-base">
                    {/* Apply the createNumberedList function to the job description */}
                    {createNumberedList(jobDetails?.job_description)?.map(
                      (item, index) => (
                        <p key={index} className="fs-5">{`${index + 1}. ${item}`}</p>
                      )
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex py-3">
                  <h4 className="w-25 d-flex justify-content-between fw-semibold">
                    <span>Qualification & Skills</span>
                    <span>:</span>
                  </h4>
                  <div className="w-75 px-3 fw-normal lh-base">
                    {/* Apply the createNumberedList function to the qualification */}
                    {createNumberedList(jobDetails?.qualification)?.map(
                      (item, index) => (
                        <p key={index} className="fs-5">{`${index + 1}. ${item}`}</p>
                      )
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex-center-both py-3">
                  <h4 className="w-25 d-flex justify-content-between fw-semibold">
                    <span>Job Type</span>
                    <span>:</span>
                  </h4>
                  <h4 className="w-75 px-3 fw-normal">
                    {jobDetails?.job_type}
                  </h4>
                </div>
                <div className="w-100 d-flex-center-both py-3">
                  <h4 className="w-25 d-flex justify-content-between fw-semibold">
                    <span>Job Location</span>
                    <span>:</span>
                  </h4>
                  <h4 className="w-75 px-3 fw-normal">
                    {jobDetails?.job_location}
                  </h4>
                </div>
                <div className="text-end py-3">
                  {date <= new Date().getTime() ? (
                    <button
                      to="/about/application/details/job-apply"
                      className="mntech-btn-fill"
                      disabled
                    >
                      Apply Now
                    </button>
                  ) : (
                    <Link
                      to={`/about/application/details/job-apply/${id}`}
                      className="mntech-btn-fill"
                    >
                      Apply Now
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default SpecificJobDetails;
