import React from "react";
import { Container } from "react-bootstrap";
import FIGMA from "../../../assets/mntechdigital-icon/ui_ux/figma.png";
import XD from "../../../assets/mntechdigital-icon/ui_ux/xd.png";
import ILLUSTRATOR from "../../../assets/mntechdigital-icon/ui_ux/illustrator.png";
import PHOTOSHOP from "../../../assets/mntechdigital-icon/ui_ux/photoshop.png";
import INDUIUX from "../../../assets/img/ind_ui_ux.png";
import UIUXBANNER from "../../../assets/img/ui_ux_banner.png";
import SpecificServBanner from "../../../components/shared/SpecificServices/SpecificServBanner";
import SpecificServDetails from "../../../components/shared/SpecificServices/SpecificServDetails";
import SpecificServTechnologies from "../../../components/shared/SpecificServices/SpecificServTechnologies";
import ServIndustries from "../../../components/shared/SpecificServices/ServIndustries";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";


import "../../../styles/Style.css";
import ScrollToDown from "../../../components/shared/ScrollToTopButton/ScrollToDown";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const UiAndUx = () => {
  // UI/UX banner elements
  const bannerElements = {
    id: 100004,
    serviceName: "UI/UX Design",
    serviceDesc: `UI (User Interface) and UX (User Experience) are two key components of website and app design.`,
    serviceImg: UIUXBANNER,
    btnAddress: "#explore",
  };

  // UI/UX service details
  const details = {
    firstParagraph: `UI refers to the visual design of the user interface and how users interact with it. It includes the layout, typography, color scheme, icons, and other elements that make up the visual design of a website or app. The UI designer is responsible for creating an interface that is visually appealing, easy to use, and consistent across different devices and platforms.
    UX, on the other hand, focuses on the overall experience of the user when interacting with a website or app. `,
    secondParagraph: `It includes how the user interacts with the interface, the flow of the design, and the overall usability of the product. The UX designer is responsible for creating an intuitive and seamless user experience that makes it easy for users to achieve their goals when using the product.
    MNTECH DIGITAL have a strong UI/UX design team, our team can create a successful and effective website or app that meets the needs of users and helps to achieve business goals.
    `,
  };

  // technologies used for UI/UX
  const technologies = [
    {
      id: 20000,
      techImg: FIGMA,
      techName: "Figma",
    },
    {
      id: 20001,
      techImg: XD,
      techName: "AdobeXD",
    },
    {
      id: 20002,
      techImg: ILLUSTRATOR,
      techName: "AdobeAi",
    },
    {
      id: 20003,
      techImg: PHOTOSHOP,
      techName: "Photoshop",
    },
  ];

  // industries
  const industries = [
    {
      id: 6000,
      indName: "Health Care",
    },
    {
      id: 6001,
      indName: "Telecommunication",
    },
    {
      id: 6002,
      indName: "Software",
    },
    {
      id: 6003,
      indName: "Real Estate",
    },
    {
      id: 6004,
      indName: "E-commerce",
    },
    {
      id: 6005,
      indName: "Education",
    },
    {
      id: 6006,
      indName: "Retail",
    },
    {
      id: 6007,
      indName: "Start Up",
    },
    {
      id: 6008,
      indName: "Non Profit",
    },
  ];

  return (
    <section className="mt-5 m-h-100">
        <PageHelmet
        title={helmetContent?.ourServices?.uiux?.title}
        description={helmetContent?.ourServices?.uiux?.desc}
      />
      <Container>
        <SpecificServBanner bannerElements={bannerElements} />
        <ScrollToDown btnAddress={bannerElements?.btnAddress} />
        <SpecificServDetails details={details} />
        <SpecificServTechnologies technologies={technologies} />
        <ServIndustries industries={industries} industryImg={INDUIUX} />
        <LetUsHelp  />
      </Container>
    </section>
  );
};

export default UiAndUx;

