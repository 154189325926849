import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function PageHelmet(props) {
  const defaultDesc = `MNTECH DIGITAL is a software company, where we promote Innovation, Business growth, Latest technology, web base solution, software application and mobile app. we have developed a range of software solutions that are designed to be both cost-effective and highly efficient.`;
  useEffect(() => {
    document.title = `${props?.title} | MNTECH DIGITAL`;
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute(
        "content",
        props?.description ? props.description : defaultDesc
      );
    }
  }, [props?.title, props?.description, defaultDesc]);

  return (
    <Helmet>
      <title>
        {props?.title.length
          ? `${props?.title} | MNTECH DIGITAL`
          : `MNTECH DIGITAL`}
      </title>

      <meta
        name="description"
        content={props?.description.length ? props?.description : defaultDesc}
      />
    </Helmet>
  );
}

export default PageHelmet;
