import React from "react";
import { Link } from "react-router-dom";

const ContactUsButton = () => {
  return (
    <div className="ms-2 mt-5">
      <Link to="/contact-us" className="mntech-btn-fill no-underline fs-5">
        Contact Us
      </Link>
    </div>
  );
};

export default ContactUsButton;
