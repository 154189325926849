import React from "react";
import { Container } from "react-bootstrap";
import DIGITALMARKETINGBANNER from "../../../assets/img/digital_marketing_banner.png";
import SEO from "../../../assets/mntechdigital-icon/digital_marketing/seo.png";
import CONTENTWRITING from "../../../assets/mntechdigital-icon/digital_marketing/content_writing.png";
import WEBLINK from "../../../assets/mntechdigital-icon/digital_marketing/web_link.png";
import EMAIL from "../../../assets/mntechdigital-icon/digital_marketing/email.png";
import SOCIALMEDIA from "../../../assets/mntechdigital-icon/digital_marketing/social_media.png";
import INDDM from "../../../assets/img/ind_dm.png";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import SpecificServBanner from "../../../components/shared/SpecificServices/SpecificServBanner";
import SpecificServDetails from "../../../components/shared/SpecificServices/SpecificServDetails";
import SpecificServTechnologies from "../../../components/shared/SpecificServices/SpecificServTechnologies";
import ServIndustries from "../../../components/shared/SpecificServices/ServIndustries";


// css style
import "../../../styles/Style.css";
import ScrollToDown from "../../../components/shared/ScrollToTopButton/ScrollToDown";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const DigitalMarketing = () => {
  // website development banner elements
  const bannerElements = {
    id: 100003,
    serviceName: "Digital Marketing",
    serviceDesc: `Digital marketing refers to the use of digital channels such as search engines, social media, email, and websites to promote products and services, build brand awareness, and engage with customers. Digital marketing offers numerous benefits for businesses and organizations.
    `,
    serviceImg: DIGITALMARKETINGBANNER,
    btnAddress: "#explore",
  };

  // website service details
  const details = {
    firstParagraph: `MNTECH DIGITAL marketing team allows businesses to target their audience more effectively. By utilizing data and analytics, businesses can identify their target audience and tailor their messaging and advertising to reach them. Our digital marketing team is often more cost-effective than traditional marketing methods such as print and TV advertising. Businesses can reach a larger audience with a smaller budget and track the results of their marketing efforts more easily`,
    secondParagraph: `Our digital marketing team allows businesses to measure the results of their marketing efforts more easily. With the use of analytics and tracking tools, businesses can track website traffic, conversions, and other key metrics to gauge the success of campaigns.
    We offers numerous benefits for businesses looking to improve their targeting, reach, cost-effectiveness, personalization, and measurement of  marketing efforts.
    `,
  };

  // technologies used for web
  const technologies = [
    {
      id: 7000,
      techImg: SEO,
      techName: "SEO",
    },
    {
      id: 7001,
      techImg: CONTENTWRITING,
      techName: "ContentWriting",
    },
    {
      id: 7002,
      techImg: WEBLINK,
      techName: "WebLink",
    },
    {
      id: 7003,
      techImg: EMAIL,
      techName: "email",
    },
    {
      id: 7004,
      techImg: SOCIALMEDIA,
      techName: "SocialMedia",
    },
  ];

  // industries
  const industries = [
    {
      id: 5500,
      indName: "Telecommunication",
    },
    {
      id: 5501,
      indName: "Real Estate",
    },
    {
      id: 5502,
      indName: "E-commerce",
    },
    {
      id: 5503,
      indName: "Health Care",
    },
    {
      id: 5504,
      indName: "Retail",
    },
  ];

  return (
    <section className="mt-5 m-h-100">
      <PageHelmet
        title={helmetContent?.ourServices?.dmkt?.title}
        description={helmetContent?.ourServices?.dmkt?.desc}
      />
      <Container>
        <SpecificServBanner bannerElements={bannerElements} />
        <ScrollToDown btnAddress={bannerElements?.btnAddress} />
        <SpecificServDetails details={details} />
        <SpecificServTechnologies technologies={technologies} />
        <ServIndustries industries={industries} industryImg={INDDM} />
        <LetUsHelp  />
      </Container>
    </section>
  );
};

export default DigitalMarketing;
