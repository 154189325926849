import React from "react";

import { Container } from "react-bootstrap";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import IndSoftwareMarketing from "../../../components/shared/SpecificIndustries/IndSoftwareMarketing";
import SpecificIndBanner from "../../../components/shared/SpecificIndustries/SpecificIndBanner";



import STARTUPBANNER from "../../../assets/img/startup_banner.png";

import CRM from "../../../assets/mntechdigital-icon/start_up/crm_1.png";
import CCT from "../../../assets/mntechdigital-icon/start_up/cct_2.png";
import CCP from "../../../assets/mntechdigital-icon/start_up/ccp_3.png";
import DAS from "../../../assets/mntechdigital-icon/start_up/das_4.png";
import SSMT from "../../../assets/mntechdigital-icon/start_up/ssmt-5.png";
import AFS from "../../../assets/mntechdigital-icon/start_up/afs-6.png";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const StartUp = () => {
  // banner description for health care industry
  const bannerDesc = {
    id: 2019,
    indName: "Start Up",
    indBannerDesc: `software is a crucial component of the startup industry, providing startups with the tools and resources they need to build, grow, and scale their businesses quickly and efficiently. By leveraging software, startups can streamline their operations, improve their customer engagement, and gain insights into their business performance, helping them to compete in a crowded marketplace and achieve long-term success.
    `,
  };

  // industries
  const specificInd = [
    {
      id: 111991,
      indName: "Customer Relationship Management",
      indDesc: `Startups use CRM software to manage customer data, track leads, and improve customer engagement.`,
      indImg: CRM,
    },
    {
      id: 111992,
      indName: "Collaboration and Communication tools",
      indDesc: `Startups often use collaboration and communication tools such as Slack, Asana, or Trello to improve team collaboration and streamline workflows.`,
      indImg: CCT,
    },
    {
      id: 111993,
      indName: "Cloud Computing platforms",
      indDesc: `Startups use cloud computing platforms such as AWS, Google Cloud, or Microsoft Azure to host and manage their software and applications, enabling them to scale their businesses quickly and efficiently.`,
      indImg: CCP,
    },
    {
      id: 111994,
      indName: "Data Analytics software",
      indDesc: `Startups use data analytics software to gather, process, and analyze large amounts of data to gain insights into customer behavior, market trends, and business performance.`,
      indImg: DAS,
    },
    {
      id: 111995,
      indName: "Social Media Management tools",
      indDesc: `Startups use social media management tools such as Hootsuite, Buffer, or Sprout Social to manage their social media presence, engage with customers, and track social media performance.`,
      indImg: SSMT,
    },
    {
      id: 111996,
      indName: "Accounting and Finance software ",
      indDesc: `Startups use accounting and finance software such as QuickBooks, Xero, or FreshBooks to manage their finances, track expenses, and generate financial reports.`,
      indImg: AFS,
    },
  ];

  return (
    <section className="mb-5">
      <PageHelmet
        title={helmetContent?.industry?.startUp?.title}
        description={helmetContent?.industry?.startUp?.desc}
      />
      <SpecificIndBanner INDBANNER={STARTUPBANNER} bannerDesc={bannerDesc} />
      <Container>
        <IndSoftwareMarketing
          specificInd={specificInd}
          indName={bannerDesc?.indName}
        />
        <LetUsHelp  />
      </Container>
    </section>
  );
};

export default StartUp;
