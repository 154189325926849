import React from "react";
import { Container } from "react-bootstrap";
import OURSTORYBANNER from "../../../assets/img/our_story_banner.png";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";


const OurStory = () => {
  return (
    <section className="m-h-100">
      <PageHelmet
        title={helmetContent?.about?.OurStory?.title}
        description={helmetContent?.about?.OurStory?.desc}
      />
      <div className="w-100 h-100 mb-5">
        <img
          className="w-100 h-100"
          src={OURSTORYBANNER}
          alt="industriesBanner"
        />
      </div>
      <Container>
        <h2 className="text-center">Our Story</h2>
        <div className="border-b" />
        <div className="mt-4 line-height-3 ta-same">
          <p className="fs-7">
            MNTECH DIGITAL is a growing tech based company that was Co- founded
            by Mosrur Rakayet in September 2022. He is a graduate of North South
            University and also the CEO of the company.
          </p>
          <p className="fs-7">
            Mosrur always been passionate about technology and innovation, and
            he saw a huge opportunity to provide businesses with comprehensive
            technological and digital solutions. Along with a group of
            like-minded entrepreneurs and foreign stakeholders, he set out to
            create a company (MNTECH DIGITAL) that could offer top-notch
            software development, web design, and digital marketing services.
            With our combined expertise in software development, web design, and
            digital marketing to create a company that could offer comprehensive
            tech based solutions to businesses of all sizes.
          </p>
          <p className="fs-7">
            MNTECH DIGITAL expanded its service offerings including web design
            and development, search engine optimization, social media
            management, digital marketing, ui/ux and online advertising. It
            quickly gained a reputation for delivering high-quality work and
            outstanding customer service, and began to attract clients from a
            wide range of industries inside and outside of country.
          </p>
          <p className="fs-7">
            We have a commitment to staying ahead of the curve in terms of
            technology and industry trends. Working Team constantly
            experimenting with new tools and techniques, and investing in
            ongoing training and development for their team members.
          </p>
          <p className="fs-7">
            We are deeply committed to building a culture of innovation and
            collaboration within the company, and our management always
            encourage team members to explore new ideas and experiment with
            emerging technologies. MNTECH DIGITAL team work closely with clients
            to understand their needs and goals and oversaw the development of
            customized solutions that could help client’s to achieve success.
          </p>
          <p className="fs-7">
            MNTECH DIGITAL is known for its tireless work ethic and ability to
            inspire. We are highly committed to continue pushing our boundaries
            of what was possible in the world of technology and innovation. We
            inspire innovation & business growth through software solutions and
            continuous support.
          </p>
        </div>
        <>
          <LetUsHelp />
        </>
      </Container>
    </section>
  );
};

export default OurStory;
