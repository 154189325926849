import { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import "./ScrollToTopButton.css";

function ScrollToTopButton() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function handleClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="animation-scroll-btn">
      {showButton && (
        <button className="scroll-to-top-button mntech-primary-clr-bg z-index-100" onClick={handleClick}>
          <FaArrowUp className="up-arrow" />
        </button>
      )}
    </div>
  );
}

export default ScrollToTopButton;
