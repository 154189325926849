import React from "react";
import { Card } from "react-bootstrap";
import { FaLinkedinIn } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { GrFacebookOption } from "react-icons/gr";
import { MdEmail } from "react-icons/md";

const ContactAdressInfo = () => {
  return (
    <>
      <div className="address-card shadow" data-aos="fade-right">
        <Card.Body className="p-5 d-flex flex-column gap-4">
          <h5 className="d-flex gap-2">
            <a
              href="tel:+8801575130259"
              className="no-underline footer-route-link underline-hover"
            >
              <span>
                <FiPhone className="address-icon" />
              </span>
              <span className="ms-3 no-underline"> P: +880 1575 130 259</span>
            </a>
          </h5>
          <h5 className="d-flex gap-2">
            <a
              href="mailto:contact@mntechdigital.com"
              className="no-underline footer-route-link underline-hover"
            >
              <span>
                <MdEmail className="address-icon" />
              </span>
              <span className="ms-3 no-underline">
                E: contact@mntechdigital.com
              </span>
            </a>
          </h5>
          <h5 className="d-flex gap-2">
            <a
              href="https://www.google.com/maps/place/MNTECH+DIGITAL/@22.686781,90.352887,20z/data=!4m6!3m5!1s0x37553594d3e577bf:0xce2cba69a27bf6c!8m2!3d22.6867403!4d90.3529076!16s%2Fg%2F11thg61t4c?hl=en"
              target="_blank"
              rel="noreferrer"
              className="no-underline footer-route-link underline-hover"
            >
              <span>
                <GoLocation className="address-icon" />
              </span>
              <span className="ms-3">2432-000, Sagordi</span>
              <br />
              <span className="ms-5">Barisal, Bangladesh</span>
            </a>
          </h5>
          <div className="follow-us">
            <h4>Follow Us</h4>
            <div className="follow-us-border" />
            <div className="mt-4">
              <a
                href="https://www.facebook.com/profile.php?id=100086473733730"
                className="facebook"
                title="mntechdigitalfacebook"
                target="_blank"
                rel="noreferrer"
              >
                <GrFacebookOption className="fs-3" />
              </a>
              <a
                href="https://www.linkedin.com/company/mntech-digital/"
                className="linkedin"
                title="mntechdigitallinkedin"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn className="fs-3" />
              </a>
            </div>
          </div>
        </Card.Body>
      </div>
    </>
  );
};

export default ContactAdressInfo;
