import React from "react";
import { Table } from "react-bootstrap";

const JobApplications = ({
  jobApplications,
  jobApplicantsPageSize,
  handlePageSizeChange,
  jobApplicantsPage,
  handleJobApplicantsPageChange,
  jobApplicantsTotalPages,
  handleResumeDelete,
  // deletedApplicantId
}) => {
  
  // remove deleted application from admin UI instantly
  // jobApplications = jobApplications.filter(application => application.id !== deletedApplicantId)

  
  return (
    <>
      {jobApplications.length ? (
        <>
          <div className="my-5">
            <h1>All Job Applications</h1>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Index</th>
                  <th>Applicant ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Department</th>
                  <th>Applicant Position</th>
                  <th>Applicant Location</th>
                  <th>Notice Period</th>
                  <th>Job Location</th>
                  <th>Resume/CV</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {jobApplications.map((applicant, idx) => (
                  <tr key={applicant.id}>
                    <td className="fw-semibold">{idx + 1}</td>
                    <td className="fw-semibold">{applicant.id}</td>
                    <td className="fw-semibold">{applicant.name}</td>
                    <td>{applicant.email}</td>
                    <td>{applicant.phone}</td>
                    <td>{applicant.department}</td>
                    <td>{applicant.apply_position}</td>
                    <td>{applicant.applicant_location}</td>
                    <td>{applicant.notice_period}</td>
                    <td>{applicant.job_location}</td>
                    <td>
                      {applicant.resume_cv && (
                        <a
                          href={`${process.env.REACT_APP_API_URL}/jobApply/download/${applicant.id}`}
                          download
                        >
                          Download CV/Resume
                        </a>
                      )}
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleResumeDelete(applicant.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Implement pagination controls for Job Applicants Form */}
            <div className="pagination-controls">
              <span>Items per page:</span>
              <select
                value={jobApplicantsPageSize}
                onChange={(e) =>
                  handlePageSizeChange("jobApplicants", Number(e.target.value))
                }
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </select>
              <span>Page:</span>
              <button
                disabled={jobApplicantsPage === 1}
                onClick={() => handleJobApplicantsPageChange(jobApplicantsPage - 1)}
              >
                Prev
              </button>
              <span>{jobApplicantsPage}</span>
              <button
                disabled={jobApplicantsPage === jobApplicantsTotalPages}
                onClick={() => handleJobApplicantsPageChange(jobApplicantsPage + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </>
      ) : (
        <h2>There is no applicant data</h2>
      )}
    </>
  );
};

export default JobApplications;
