export const helmetContent = {
  default: {
    title: "MNTECH DIGITAL",
    desc: "MNTECH DIGITAL is a software company, where we promote Innovation, Business growth, Latest technology, web base solution, software application and mobile app. we have developed a range of software solutions that are designed to be both cost-effective and highly efficient.",
  },
  ourServices: {
    title: "Our Services",
    desc: "MNTECHDIGITAL helps you to stand out in a crowded marketplace, attract more traffic to your site, and ultimately drive more leads and revenue.",
    web: {
      title: "Web Development",
      desc: "MNTECHDIGITAL Specialized in creating high-quality, websites that is unique for your business.",
    },
    mobileApp: {
      title: "Mobile App Development",
      desc: "MNTECHDIGITAL team can help businesses to engage with customers in a more personalized and interactive way. By providing personalized content, push notifications, and in-app messaging, client businesses can build stronger relationships with their customers.",
    },
    dmkt: {
      title: "Digital Marketing",
      desc: "MNTECHDIGITAL marketing team allows businesses to target their audience more effectively.",
    },
    uiux: {
      title: "UI/UX",
      desc: "MNTECHDIGITAL have a strong UI/UX design team, our team can create a successful and effective website or app that meets the needs of users and helps to achieve business goals.",
    },
    dataEntry: {
      title: "Data Entry",
      desc: "MNTECHDIGITAL has a big team who are a part of digitizing data by entering it into a computer system for organization and management purposes.",
    },
    qa: {
      title: "Quality Assurance",
      desc: "MNTECHDIGITAL assure quality assurance as a Service  that can be a valuable option for businesses that want to ensure the quality of their software products while also saving time and reducing costs.",
    },
  },

  industry: {
    title: "Industries",
    desc: "Mntech digital team of professionals will work in different industries.",
    erp: {
      title: "Enterprise Resource Planning",
      desc: "Mntech digital team of professionals will take care of your business's bottlenecks. Whether its development services, implementation, or even dedicated resources- get in touch with us for your ERP needs",
    },
    healthCare: {
      title: "Health Care",
      desc: "Mntech digital  healthcare Software has become increasingly important in the healthcare industry, as it can help improve patient care, streamline operations, and reduce costs.",
    },
    telecom: {
      title: "Telecommunication",
      desc: "Mntech digital helps telecommunication companies manage their networks, improve customer experience, and develop new products and services.",
    },
    realEstate: {
      title: "Real Estate",
      desc: "Mntech digital helps real estate companies manage their properties, streamline operations, and provide better customer service.",
    },
    eCom: {
      title: "E-commerce",
      desc: "Mntech digital helps  e- commerce companies to sell products and services online, manage inventory, streamline operations, and provide a seamless customer experience.",
    },
    software: {
      title: "Software",
      desc: "Mntech digital helps Software companies build their Integrated Development Environments (IDEs),Content Management Systems (CMS),Customer Relationship Management (CRM) software,Security software,Productivity software,Data analysis software.",
    },
    education: {
      title: "Education",
      desc: "Mntech digital plays a crucial role in the education industry, as it provides educators and students with tools and resources that facilitate learning, increase efficiency, and improve educational outcomes. ",
    },
    retail: {
      title: "Retail",
      desc: "Mntech digital is capable of playing a critical role in the retail industry, providing retailers with the tools and resources they need to manage their operations, engage with customers, and increase sales.",
    },
    startUp: {
      title: "Start Up",
      desc: "Mntech digital  is a crucial component of the startup industry, providing startups with the tools and resources they need to build, grow, and scale their businesses quickly and efficiently.",
    },
    nonProfit: {
      title: "Non-profit",
      desc: "Mntech digital is capable of playing a critical role in the nonprofit industry, providing organizations with the resources they need to manage their operations, engage with donors and volunteers, engage with supporters, and achieve their mission. ",
    },
  },
  about: {
    OurStory: {
      title: "Our Story",
      desc: "mntechdigital  is a growing tech based company. mntechdigital are highly committed to continue pushing in the world of technology and innovation. mntechdigital inspire innovation & business growth through software solutions and continuous support.",
    },
    profile: {
      title: "Our Profile",
      desc: "mntechdigital Co-founded by Mosrur Rakayet and he is also CEO. CFO is Rezwan, and app development head sayed.",
    },
    blogs: {
      title: "Blogs",
      desc: "mntechdigital employed write down their blogs with their knowledge and experience in real world.",
    },
    career: {
      title: "Career",
      desc: "mntechdigital have offered different post,and positions every year. If you have interested in working then drop your cv.",
    },
  },
  contactUs: {
    title: "Contact Us",
    desc: "mntech digital  is a growing tech based company. mntech digital helps you with your software and gives you a good professional experience with your personal software.",
  },
};
