import React from "react";
import ContactUsButton from "../ContactUsButton/ContactUsButton";
import "./LetUsHelp.css";

const LetUsHelp = () => {
  return (
    <>
      <section className="my-12" data-aos="fade-up">
        <div className="d-flex justify-content-center">
          <div className="mb-0">
            <p className="fs-md-8 fs-lg-4">
              Let Us Help You With Your Project
            </p>
            <div className="text-end">
              <ContactUsButton />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LetUsHelp;
