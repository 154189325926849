import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import axios from "axios";

function ContactUsForm() {
  const {
    register,
    handleSubmit,
    formState: { errors, isLoading},
    reset,
  } = useForm();

  const handleContactSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contact`,
        data
      );
      // console.log(response);
      if (response.data.status) {
        toast.success(`${response?.data?.message}`, {
          style: {
            borderRadius: "10px",
            background: "#222",
            color: "#fff",
          },
        });
      }
    } catch (error) {
      toast.error("Failed To Sent, Please Check Your Internet Connection!", {
        style: {
          borderRadius: "10px",
          background: "#222",
          color: "#fff",
        },
      });
    }

    reset();
  };

  return (
    <div className="mt-7 pb-5 bt-light-gray">
      <div data-aos="fade-down">
        <h2 className="text-center">Countact Us</h2>
        <div className="border-b" />
      </div>
      <form
        className="md-w-100 w-60 justify-content-center mx-auto mt-5"
        onSubmit={handleSubmit(handleContactSubmit)}
        data-aos="fade-up"
      >
        <div className="md-f-col d-flex gap-x-2 w-100">
          <div className="d-flex flex-column w-100 input-parent">
            <input
              type="name"
              className={`md-w-100 lg-w-50 input-field ${
                errors.name ? "is-invalid" : ""
              }`}
              placeholder="Name"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <span className="text-danger">Please enter your name.</span>
            )}
          </div>
          <div className="d-flex flex-column w-100">
            <input
              type="email"
              className={`md-w-100 lg-w-50 input-field ${
                errors.email ? "is-invalid" : ""
              }`}
              placeholder="Email"
              {...register("email", {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
            />
            {errors.email && (
              <span className="text-danger">
                Please enter a valid email address.
              </span>
            )}
          </div>
        </div>
        <div className="input-parent mt-3 mt-lg-0">
          <input
            type="text"
            className={`w-100 input-field ${
              errors.subject ? "is-invalid" : ""
            }`}
            placeholder="Subject"
            {...register("subject", { required: true })}
          />
          {errors.subject && (
            <span className="text-danger">Please enter a subject.</span>
          )}
        </div>
        <div className="input-parent">
          <textarea
            className={`w-100 text-area input-field ${
              errors.message ? "is-invalid" : ""
            }`}
            placeholder="Your Message"
            {...register("message", { required: true })}
          />
          {errors.message && (
            <span className="text-danger">Please enter a message.</span>
          )}
        </div>
        <button
          className="contact-msg-btn uppercase"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <><span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
          <span>Sending...</span></>
          ) : (
            "Send Message"
          )}
        </button>
        {/* <button type="submit" className="contact-msg-btn uppercase">
          Send Message
        </button> */}
      </form>
    </div>
  );
}

export default ContactUsForm;
