import React from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import BLOG1 from "../../../../assets/img/blog_img.png";

const SpecificBlog = () => {
  const location = useLocation();
  const blogId = location.pathname.split("/")[3];
  // console.log(blogId)

  // blogs
  const blogs = [
    {
      id: 5670,
      blogTitle: `Know Machine learning and artificial intelligence with MNTech Digital`,
      blogImg: BLOG1,
      blogHeadingText: `Machine learning and artificial intelligence (AI) have become buzzwords in recent years, 
      but they are not new concepts. Both have a long history dating back to the 1940s and 1950s, when the first
      computers were being developed. In this blog, we will discuss the history of machine learning and AI, their 
      importance, and their uses in today's business world, as well as the future scope of these technologies.
      `,
      blogBodyText: [
        {
          id: 321121,
          bodyHeading: "History of Machine Learning and AI",
          bodyHeadingText: (
            <p className="fs-7">
              The history of machine learning and AI can be traced back to the
              1940s, when the first electronic computers were being developed.
              Early pioneers in this field, such as Alan Turing, proposed the
              idea of creating machines that could "think" like humans. In the
              1950s, researchers began developing early AI programs, such as the
              first chess-playing computer program.
              <br />
              In the 1960s and 1970s, researchers made significant progress in
              the field of AI, including the development of expert systems that
              could mimic the decision-making abilities of human experts.
              However, progress slowed in the 1980s due to the lack of available
              data and computational power.
              <br />
              <br />
              Machine learning emerged as a subfield of AI in the 1990s,
              focusing on developing algorithms that could learn from data. This
              approach allowed machines to improve their performance over time
              without being explicitly programmed. Today, machine learning is
              used in a wide range of applications, including image and speech
              recognition, fraud detection, and medical diagnosis.
            </p>
          ),
        },
        {
          id: 321122,
          bodyHeading: "Importance of Machine Learning and AI",
          bodyHeadingText: (
            <p className="fs-7">
              Machine learning and AI are important because they can help
              organizations make more informed decisions, automate tasks, and
              identify patterns that would be difficult for humans to detect.
              These technologies are particularly useful in areas where there is
              a large amount of data, such as finance, healthcare, and retail.
              <br />
              <br />
              In addition, machine learning and AI have the potential to
              revolutionize industries by creating new business models and
              disrupting traditional ones. For example, self-driving cars could
              transform the transportation industry, while chatbots and virtual
              assistants could change the way businesses interact with
              customers.
            </p>
          ),
        },
        {
          id: 321123,
          bodyHeading:
            "Uses of Machine Learning and AI in Today's Business World",
          bodyHeadingText: (
            <p className="fs-7">
              In today's business world, machine learning and AI are being used
              in a wide range of applications. Some examples include:
              <br />
              <b>Predictive analytics:</b> Machine learning algorithms can be
              used to predict customer behavior, market trends, and other
              business outcomes.
              <br />
              <b>Chatbots and virtual assistants:</b> AI-powered chatbots and
              virtual assistants can provide customer support and help automate
              tasks.
              <br />
              <b>Fraud detection:</b> Machine learning algorithms can help
              detect fraudulent transactions and prevent financial losses.
              <br />
              <b>Personalization:</b> Machine learning can be used to
              personalize marketing messages and recommendations based on
              customer data.
            </p>
          ),
        },
        {
          id: 321124,
          bodyHeading: "Future Scope of Machine Learning and AI",
          bodyHeadingText: (
            <p className="fs-7">
              The future scope of machine learning and AI is vast and exciting.
              Some experts predict that AI could eventually surpass human
              intelligence and change the way we live our lives. In the business
              world, machine learning and AI are likely to continue to play an
              important role in helping organizations make more informed
              decisions and improve their operations.
              <br />
              <br />
              Some potential future applications of machine learning and AI
              include:
              <br />
              <br />
              Autonomous vehicles: Self-driving cars could become more common,
              changing the way we travel and reducing accidents on the road.
              <br />
              <b> Healthcare:</b> Machine learning algorithms could be used to
              develop personalized treatments for patients based on their
              genetics and medical history.
              <br />
              <b>Robotics:</b> Robots could become more common in industries
              such as manufacturing and logistics, helping to automate tasks and
              improve efficiency. <br />
              <br />
              The future of AI is very promising, and MNTech Digital recognizes
              this fact. With advancements in machine learning, natural language
              processing, and deep learning, AI has the potential to
              revolutionize the way we work and live. This technology can
              automate routine tasks, analyze data at scale, and provide
              predictive insights that can help businesses make better
              decisions.
              <br />
              MNTech Digital's AI-based work is primarily focused on developing
              intelligent systems that can analyze data and provide insights to
              our customers. The company has been investing heavily in
              developing machine learning algorithms that can help businesses
              predict trends, identify patterns, and make informed decisions.
              With AI, we can provide our clients with personalized and relevant
              solutions that meet their specific needs.
              <br />
              One of the areas where MNTech Digital is making significant
              strides is in natural language processing (NLP). NLP is a branch
              of AI that deals with the interaction between computers and human
              language. We have been developing NLP-based solutions that can
              help businesses automate customer service operations. With
              AI-powered chatbots, we can help businesses respond to customer
              inquiries faster and more accurately, leading to higher customer
              satisfaction.
              <br />
              MNTech Digital is also exploring other areas of AI, such as
              computer vision and robotics. These areas have the potential to
              transform various industries, from manufacturing to healthcare.
              With AI-powered robots and machines, we can help businesses
              automate their operations, reduce costs, and improve efficiency.
              <br />
              MNTech Digital is a software company that is well-positioned to
              take advantage of the exciting possibilities that AI offers. With
              its focus on developing AI-based solutions, the company is poised
              to help businesses stay competitive in an increasingly data-driven
              world. As AI continues to evolve, we are sure to be at the
              forefront of this exciting technological revolution.
            </p>
          ),
        },
      ],
    },
  ];

  // get specific blog
  const specificBlog = blogs.filter((blog) => blog.id === +blogId);
  const blog = specificBlog[0];

  return (
    <Container className="my-5">
      <h1>{blog?.blogTitle}</h1>
      <p className="mt-4 fs-7 ta-same">{blog?.blogHeadingText}</p>
      <img
        className="img-fluid my-3 w-100"
        style={{ height: "50%" }}
        src={blog?.blogImg}
        alt="blogImg"
      />

      <div className="mt-2 mb-5">
        {blog?.blogBodyText?.map((bodyText) => (
          <div className="mt-4">
            <h4 className="fw-semibold ta-same">{bodyText?.bodyHeading}</h4>
            {/* <p className="fs-7">{bodyText?.bodyHeadingText}</p> */}
            <div className="ta-same">{bodyText?.bodyHeadingText}</div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default SpecificBlog;
