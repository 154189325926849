import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
// import LoadingSpinner from "./components/shared/LoadingSpinner/LoadingSpinner";
import { router } from "./routes/Routes";
import { Toaster } from "react-hot-toast";

// fade animation with aos
import AOS from "aos";
import "aos/dist/aos.css";

import "./styles/utilityClass.css";
import { HelmetProvider } from "react-helmet-async";

function App() {
  /** this code is commented becouse of its not working on phone properly */
  // state for primary window loading
  // const [isLoading, setIsLoading] = useState(true);

  // fist loading information
  // useEffect(() => {
  //   console.log(window.screen);
  //   const windowWidth = window.screen;

  //   if (windowWidth.width > 991) {
  //     // Hide the loading message when the page finishes loading
  //     const handleLoad = () => setIsLoading(false);
  //     window.addEventListener("load", handleLoad);

  //     // Show the loading message when the page is about to be unloaded
  //     const handleUnload = () => setIsLoading(true);
  //     window.addEventListener("unload", handleUnload);

  //     return () => {
  //       // Remove the load event listener on cleanup
  //       window.removeEventListener("load", handleLoad);

  //       // Remove the unload event listener on cleanup
  //       window.removeEventListener("unload", handleUnload);
  //     };
  //   }
  // }, []);

  // aos animation init set up
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const warningMsg = "STOP";
  const warningMsg2 = "Do not change or edit anything from this devtool. If you do you probably could be ban from us forever!!!";
  console.log(
    `%c${warningMsg}`,
    "color: red; font-size: 50px; font-weight: bold;"
  );
  console.log(`%c${warningMsg2}`,
  "color: white; font-size: 20px; font-weight: normal;")

  return (
    <HelmetProvider>
      {/* {isLoading ? (
        <LoadingSpinner />
      ) : ( */}
      <>
        <Toaster position="bottom-center" reverseOrder={false} />
        <RouterProvider router={router} />
      </>
      {/* )} */}
    </HelmetProvider>
  );
}

export default App;
