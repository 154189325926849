import React from "react";
import { Container } from "react-bootstrap";
import SpecificServBanner from "../../../components/shared/SpecificServices/SpecificServBanner";
import SpecificServDetails from "../../../components/shared/SpecificServices/SpecificServDetails";
import SpecificServTechnologies from "../../../components/shared/SpecificServices/SpecificServTechnologies";
import ServIndustries from "../../../components/shared/SpecificServices/ServIndustries";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import QandAOffer from "../../../components/QAndA/QandAOffer";
import QABANNER from "../../../assets/img/qa_banner.png";
import SELENIUM from "../../../assets/mntechdigital-icon/qa/selenium.png";
import AGILE from "../../../assets/mntechdigital-icon/qa/agile.png";
import TESTLINK from "../../../assets/mntechdigital-icon/qa/testlink.png";
import MANTIST from "../../../assets/mntechdigital-icon/qa/montis.png";
import BUGZILA from "../../../assets/mntechdigital-icon/qa/bugzila.png";
import INDQA from "../../../assets/img/ind_qa.png";


import "../../../styles/Style.css";
import ScrollToDown from "../../../components/shared/ScrollToTopButton/ScrollToDown";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const QAndA = () => {
  // website development banner elements
  const bannerElements = {
    id: 100006,
    serviceName: "Quality assurance",
    serviceDesc: `Quality Assurance as a Service (QaaS) is a service provided by third-party vendors that offers a range of quality assurance and testing services for software development projects.`,
    serviceImg: QABANNER,
    btnAddress: "#explore",
  };

  // website service details
  const details = {
    firstParagraph: `QaaS providers can help businesses to ensure that their software products meet high-quality standards and are free of defects or issues.
    QaaS services typically include a range of testing and quality assurance services, including functional testing, performance testing, security testing, and user acceptance testing, among others. QaaS providers may also offer additional services such as test automation, test management, and continuous testing.
    `,
    secondParagraph: `By outsourcing QA services to a third-party provider, businesses can benefit from access to specialized expertise, resources, and tools. QaaS providers typically have experience working on a range of software development projects and can provide insights and recommendations to help businesses improve their development processes and ensure high-quality products.
    Quality Assurance as a Service can be a valuable option for businesses that want to ensure the quality of their software products while also saving time and reducing costs.`,
  };

  // technologies used for web
  const technologies = [
    {
      id: 45000,
      techImg: SELENIUM,
      techName: "SELENIUM",
    },
    {
      id: 45001,
      techImg: AGILE,
      techName: "AGILE",
    },
    {
      id: 45003,
      techImg: TESTLINK,
      techName: "TESTLINK",
    },
    {
      id: 45005,
      techImg: MANTIST,
      techName: "MANTIST",
    },
    {
      id: 45004,
      techImg: BUGZILA,
      techName: "BUGZILA",
    },
  ];

  // industries
  const industries = [
    {
      id: 65000,
      indName: "Health Care",
    },
    {
      id: 65001,
      indName: "Telecommunication",
    },
    {
      id: 65002,
      indName: "Software",
    },
    {
      id: 65003,
      indName: "Real Estate",
    },
    {
      id: 65004,
      indName: "E-commerce",
    },
    {
      id: 65005,
      indName: "Education",
    },
    {
      id: 65006,
      indName: "Retail",
    },
    {
      id: 65007,
      indName: "Start Up",
    },
    {
      id: 65008,
      indName: "Non Profit",
    },
  ];

  return (
    <section className="mt-5 m-h-100">
       <PageHelmet
        title={helmetContent?.ourServices?.qa?.title}
        description={helmetContent?.ourServices?.qa?.desc}
      />
      <Container>
        <SpecificServBanner bannerElements={bannerElements} />
        <ScrollToDown btnAddress={bannerElements?.btnAddress} />
        <SpecificServDetails details={details} />
        <QandAOffer />
        <SpecificServTechnologies technologies={technologies} />
        <ServIndustries industries={industries} industryImg={INDQA} />
        <LetUsHelp  />
      </Container>
    </section>
  );
};

export default QAndA;
