import React, { useState } from "react";
import { Card, Row, Col, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import officeLocation from "../../../assets/mntechdigital-icon/job_post_apply/location.png";
import office_building from "../../../assets/mntechdigital-icon/job_post_apply/office_building.png";
import UpdateJobModal from "./UpdateJobModal";
import axios from "axios";
import { toast } from "react-hot-toast";

const JobPosition = ({
  job_id,
  job_title,
  vacancy_quantity,
  deadline,
  job_description,
  qualification,
  location,
  job_type,
  getUpdatedJob,
  setIsUpdateJob
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  // get token
  const token = localStorage.getItem("mntechAuthToken");

  // convert deadline from milliseconds to human readable
  const date = new Date(deadline);

  const formattedDate = date.toLocaleDateString("en-BD", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  // delete the job post
  const handleDelete = async (jobId) => {
    try {
      const token = localStorage.getItem("mntechAuthToken");

      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/jobposts/${jobId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // Handle the response accordingly
      if (response.data.status) {
        toast.success(response.data.message, {
          style: {
            borderRadius: "10px",
            background: "#222",
            color: "#fff",
          },
        });
        getUpdatedJob(jobId);
        setShowModal(false);
      }
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error("Failed to delete job post:", error);
    }

    setShowModal(false);
  };

  const handleClose = () => {
    // Close the modal after update
    setShowUpdateModal(false);
  };

  return (
    <div className="my-4">
      <Card className="card-shadow card-hover border-0">
        <Card.Body>
          <Row>
            <Col
              md={5}
              className="border-right md-border-right-none d-flex align-items-center"
            >
              <div>
                <h3 className="fs-3 fw-semibold">{job_title}</h3>
                <div className="d-flex gap-4 mt-3">
                  <div className="d-flex gap-2">
                    <img
                      src={office_building}
                      alt="building"
                      width="18px"
                      height="18px"
                    />
                    <p className="mntech-primary-clr-text fs-8">
                      MNTECH DIGITAL
                    </p>
                  </div>
                  <div className="d-flex gap-2">
                    <img
                      src={officeLocation}
                      alt="building"
                      width="18px"
                      height="18px"
                    />
                    <p className="mntech-primary-clr-text fs-8">
                      Sagordi Barisal, Bangladesh
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              md={4}
              className="border-right md-border-right-none d-flex align-items-center"
            >
              <div>
                <h5>
                  <span className="fw-semibold">Deadline:</span>{" "}
                  <span>{formattedDate}</span>
                </h5>
                <p className="mt-3 mntech-primary-clr-text">
                  <span>No of vacancies: </span>
                  <span>{vacancy_quantity}</span>
                </p>
              </div>
            </Col>
            <Col md={3} className="d-flex-center-both md-d-flex-right">
              <>
                {!token ? (
                  <Link
                    to={`/about/application/${job_id}`}
                    className="no-underline mntech-btn-fill"
                  >
                    See Details
                  </Link>
                ) : (
                  ""
                )}

                {token ? (
                  <>
                    {" "}
                    <div className="d-flex gap-4">
                      <button
                        className="btn border-info btn-sm"
                        onClick={() => setShowUpdateModal(true)}
                      >
                        Update
                      </button>

                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => setShowModal(true)}
                      >
                        Delete
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <>
        {/* Delete job post permission modal */}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          className="mt-6"
        >
          <Modal.Header closeButton>
            <Modal.Title>Permission Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to delete this{" "}
              <span className="fw-bold">'{job_title}'?</span>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => handleDelete(job_id)}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <>
        {/* modal to update job post  */}
        <UpdateJobModal
          show={showUpdateModal}
          handleClose={handleClose}
          jobId={job_id}
          jobTitle={job_title}
          vacancyQuantity={vacancy_quantity}
          jobDeadline={deadline}
          jobDescription={job_description}
          jobQualification={qualification}
          jobLocation={location}
          jobType={job_type}
          token={token}
          setIsUpdateJob={setIsUpdateJob}
        />
      </>
    </div>
  );
};

export default JobPosition;
