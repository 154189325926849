import React from "react";

import { Container } from "react-bootstrap";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import IndSoftwareMarketing from "../../../components/shared/SpecificIndustries/IndSoftwareMarketing";
import SpecificIndBanner from "../../../components/shared/SpecificIndustries/SpecificIndBanner";



import RETAILBANNER from "../../../assets/img/retail_banner.png";

import POS from "../../../assets/mntechdigital-icon/retail/pos_1.png";
import IMS from "../../../assets/mntechdigital-icon/retail/ims_2.png";
import CRM from "../../../assets/mntechdigital-icon/retail/crm_3.png";
import ECOM from "../../../assets/mntechdigital-icon/retail/e_com_4.png";
import LP from "../../../assets/mntechdigital-icon/retail/lp_5.png";
import ARS from "../../../assets/mntechdigital-icon/retail/ars_6.png";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const Retail = () => {
  // banner description for health care industry
  const bannerDesc = {
    id: 2018,
    indName: "Retail",
    indBannerDesc: `Software plays a critical role in the retail industry, 
    providing retailers with the tools and resources they need to manage their
    operations, engage with customers, and increase sales. By leveraging software, 
    retailers can improve their efficiency, streamline their processes, and gain 
    nsights into their business, enabling them to compete effectively in a rapidly changing industry.`,
  };

  // industries
  const specificInd = [
    {
      id: 111881,
      indName: "Point of Sale (POS)",
      indDesc: `This software is used to manage transactions at the cash register, including processing payments, tracking inventory, and generating sales reports.`,
      indImg: POS,
    },
    {
      id: 111882,
      indName: "Inventory Management software",
      indDesc: `This software is used to track inventory levels, manage stock orders and returns, and monitor product performance.`,
      indImg: IMS,
    },
    {
      id: 111883,
      indName: "Customer Relationship Management",
      indDesc: `Retailers use CRM software to manage customer data, track customer behavior, and improve customer engagement.`,
      indImg: CRM,
    },
    {
      id: 111884,
      indName: "E-commerce Platforms",
      indDesc: `- E-commerce platforms such as Shopify, Magento, and WooCommerce are used by retailers to manage online stores, process online payments, and track online sales.`,
      indImg: ECOM,
    },
    {
      id: 111885,
      indName: "Loyalty Programs",
      indDesc: `Retailers use loyalty program software to create and manage customer loyalty programs, including rewards, incentives, and promotions.`,
      indImg: LP,
    },
    {
      id: 111886,
      indName: "Analytics and Reporting software",
      indDesc: `This software is used to analyze sales data, customer behavior, and market trends to gain insights into the business and make informed decisions.`,
      indImg: ARS,
    },
  ];

  return (
    <section className="mb-5">
      <PageHelmet
        title={helmetContent?.industry?.retail?.title}
        description={helmetContent?.industry?.retail?.desc}
      />
      <SpecificIndBanner INDBANNER={RETAILBANNER} bannerDesc={bannerDesc} />
      <Container>
        <IndSoftwareMarketing
          specificInd={specificInd}
          indName={bannerDesc?.indName}
        />
        <LetUsHelp  />
      </Container>
    </section>
  );
};

export default Retail;
