import React from "react";

const SpecificIndDetails = ({ indDetails }) => {
  const { firstParagraph, secondParagraph } = indDetails;
  return (
    <section className="mt-6 md-f-col d-flex d-flex-center-both gap-3 gap-md-4 gap-lg-5">
      <div className="specific-desc" data-aos="fade-right">
        <p>{firstParagraph}</p>
      </div>
      <div className="specific-desc" data-aos="fade-left">
        <p>{secondParagraph}</p>
      </div>
    </section>
  );
};

export default SpecificIndDetails;
