import React from "react";
import { Container } from "react-bootstrap";
import JoinAsPartnerBanner from "../../../components/Home/BecomePartner/JoinAsPartner/JoinAsPartnerBanner";
import JoinAsPartnerForm from "../../../components/Home/BecomePartner/JoinAsPartner/JoinAsPartnerForm";
import "./JoinAsPartner.css";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const JoinAsPartner = () => {
  return (
    <>
      <PageHelmet
        title="Join as a partner"
        description={helmetContent?.default?.desc}
      />
      <JoinAsPartnerBanner />
      <Container>
        <JoinAsPartnerForm />
      </Container>
    </>
  );
};

export default JoinAsPartner;
