import React from "react";
import { Container } from "react-bootstrap";
import SpecificServBanner from "../../../components/shared/SpecificServices/SpecificServBanner";
import SpecificServDetails from "../../../components/shared/SpecificServices/SpecificServDetails";
import SpecificServTechnologies from "../../../components/shared/SpecificServices/SpecificServTechnologies";
import ServIndustries from "../../../components/shared/SpecificServices/ServIndustries";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import DATAENTRY from "../../../assets/img/data_entry_banner.png";
import DATAEX from "../../../assets/mntechdigital-icon/data_entry/email_extractor.png";
import EXCEL from "../../../assets/mntechdigital-icon/data_entry/excel.png";
import WORD from "../../../assets/mntechdigital-icon/data_entry/word.png";
import DATASCRUPPER from "../../../assets/mntechdigital-icon/data_entry/data_scrupper.png";
import INDDATAENTRY from "../../../assets/img/ind_data_entr.png";


import "../../../styles/Style.css";
import ScrollToDown from "../../../components/shared/ScrollToTopButton/ScrollToDown";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const DataEntry = () => {
  // website development banner elements
  const bannerElements = {
    id: 100005,
    serviceName: "Data Entry",
    serviceDesc: `Data entry refers to the process of inputting information or data into a computer or database. This can include various types of data such as text, numbers, images, or other forms of digital content.`,
    serviceImg: DATAENTRY,
    btnAddress: "#explore",
  };

  // website service details
  const details = {
    firstParagraph: `Data entry is an important task for many businesses and organizations as 
    it helps to maintain accurate records, track important information, and streamline various processes. 
    Some common examples of data entry tasks include inputting customer information into a database, recording 
    financial transactions, or entering survey responses into a spreadsheet. Data entry requires attention to detail 
    and accuracy, as errors in data entry can have serious consequences such as incorrect financial records or 
    miscommunication with clients or customers.
    Our Data entry team can help to organize information in a way that is easy to access and use.`,
    secondParagraph: `This can improve overall efficiency and productivity, and can help businesses 
    to make more informed decisions. Our Data entry team can help ensure that information is accurately 
    captured and recorded. This can help to minimize errors and prevent   miscommunications, which can 
    save time and resources in the long run. Data entry is an important task for many businesses and organizations, 
    and can provide numerous benefits including improved accuracy, time-saving, better organization, and cost-effectiveness. 
    MNTECH DIGITAL  has a big team who are a part of digitizing data by entering it into a computer system for organization 
    and management purposes.`,
  };

  // technologies used for web
  const technologies = [
    {
      id: 12000,
      techImg: DATAEX,
      techName: "DataEx",
    },
    {
      id: 12001,
      techImg: EXCEL,
      techName: "React Native",
    },
    {
      id: 12002,
      techImg: WORD,
      techName: "Swift",
    },
    {
      id: 12003,
      techImg: DATASCRUPPER,
      techName: "Xamarin",
    },
  ];

  // industries
  const industries = [
    {
      id: 9000,
      indName: "Health Care",
    },
    {
      id: 9001,
      indName: "Telecommunication",
    },
    {
      id: 6002,
      indName: "Software ",
    },
    {
      id: 9003,
      indName: "Real Estate",
    },
    {
      id: 9004,
      indName: "E-commerce",
    },
    {
      id: 9005,
      indName: "Education",
    },
    {
      id: 9006,
      indName: "Retail",
    },
    {
      id: 9007,
      indName: "Start Up",
    },
    {
      id: 9008,
      indName: "Non Profit",
    },
  ];

  return (
    <section className="mt-5 m-h-100">
        <PageHelmet
        title={helmetContent?.ourServices?.dataEntry?.title}
        description={helmetContent?.ourServices?.dataEntry?.desc}
      />
      <Container>
        <SpecificServBanner bannerElements={bannerElements} />
        <ScrollToDown btnAddress={bannerElements?.btnAddress} />
        <SpecificServDetails details={details} />
        <SpecificServTechnologies technologies={technologies} />
        <ServIndustries industries={industries} industryImg={INDDATAENTRY} />
        <LetUsHelp  />
      </Container>
    </section>
  );
};

export default DataEntry;
