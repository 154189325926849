import React, { useEffect, useReducer } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-hot-toast";
import LoadingSpinner from "../../components/shared/LoadingSpinner/LoadingSpinner";
import AdminJobPosting from "../../components/Admin/AdminJobPosting";
import { useNavigate } from "react-router-dom";
import AllContactFormData from "../../components/Admin/AllContactFormData";
import AllJoinUsData from "../../components/Admin/AllJoinUsData";
import JobApplications from "../../components/Admin/JobApplications";
import axios from "axios";
import { adminContentReducer, initialState } from "./AdminContentReducer";

const AdminContentPage = () => {
  const [state, dispatch] = useReducer(adminContentReducer, initialState);
  const navigate = useNavigate();

  const {
    isLoading,
    allContactFormData,
    allJoinUsData,
    jobApplications,
    authToken,
    contactFormPage,
    joinUsFormPage,
    jobApplicantsPage,
    contactFormTotalPages,
    joinUsFormTotalPages,
    jobApplicantsTotalPages,
    contactFormPageSize,
    joinUsFormPageSize,
    jobApplicantsPageSize,
  } = state;

  const handleContactFormPageChange = (pageNumber) => {
    dispatch({ type: "SET_CONTACT_FORM_PAGE", payload: pageNumber });
  };

  const handleJoinUsFormPageChange = (pageNumber) => {
    dispatch({ type: "SET_JOIN_US_FORM_PAGE", payload: pageNumber });
  };

  const handleJobApplicantsPageChange = (pageNumber) => {
    dispatch({ type: "SET_JOB_APPLICANTS_PAGE", payload: pageNumber });
  };

  async function fetchData(url, token, setData, page, limit, setTotalPages) {
    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
        params: { page, limit },
      });
      setData(response.data.results);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      toast.error("Unauthorized Access");
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("mntechAuthToken");
    dispatch({ type: "SET_AUTH_TOKEN", payload: token });

    if (!token) {
      return navigate("/");
    }

    async function fetchDataWithToken() {
      dispatch({ type: "SET_LOADING", payload: true });

      // Call the 3 protected APIs with pagination data
      await fetchData(
        `${process.env.REACT_APP_API_URL}/protected/contact-form`,
        token,
        (data) =>
          dispatch({ type: "SET_ALL_CONTACT_FORM_DATA", payload: data }),
        contactFormPage,
        contactFormPageSize,
        (totalPages) =>
          dispatch({
            type: "SET_CONTACT_FORM_TOTAL_PAGES",
            payload: totalPages,
          })
      );
      await fetchData(
        `${process.env.REACT_APP_API_URL}/protected/join-as-partner`,
        token,
        (data) => dispatch({ type: "SET_ALL_JOIN_US_DATA", payload: data }),
        joinUsFormPage,
        joinUsFormPageSize,
        (totalPages) =>
          dispatch({
            type: "SET_JOIN_US_FORM_TOTAL_PAGES",
            payload: totalPages,
          })
      );
      await fetchData(
        `${process.env.REACT_APP_API_URL}/protected/applicants`,
        token,
        (data) => dispatch({ type: "SET_JOB_APPLICATIONS", payload: data }),
        jobApplicantsPage,
        jobApplicantsPageSize,
        (totalPages) =>
          dispatch({
            type: "SET_JOB_APPLICANTS_TOTAL_PAGES",
            payload: totalPages,
          })
      );

      dispatch({ type: "SET_LOADING", payload: false });
    }

    fetchDataWithToken();
  }, [
    contactFormPage,
    contactFormPageSize,
    joinUsFormPage,
    joinUsFormPageSize,
    jobApplicantsPage,
    jobApplicantsPageSize,
    navigate,
  ]);

  // Function to remove the deleted application from the state
  const removeDeletedApplication = (applicantId) => {
    dispatch({
      type: "REMOVE_DELETED_APPLICATION",
      payload: applicantId,
    });
  };

  const handleResumeDelete = async (applicantId) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this resume?"
    );
    if (!shouldDelete) return;

    dispatch({ type: "SET_LOADING", payload: true });
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/jobApply/delete/${applicantId}`
      );

      if (response?.data?.results.affectedRows) {
        toast.success(response.data.message, {
          style: {
            borderRadius: "10px",
            background: "#222",
            color: "#fff",
          },
        });

        // Remove the deleted application from the state
        removeDeletedApplication(applicantId);
      }
    } catch (error) {
      toast.error("Failed to delete resume! Please try again later.");
    }

    dispatch({ type: "SET_LOADING", payload: false });
  };

  const handlePageSizeChange = (section, pageSize) => {
    switch (section) {
      case "contactForm":
        dispatch({ type: "SET_CONTACT_FORM_PAGE_SIZE", payload: pageSize });
        dispatch({ type: "SET_CONTACT_FORM_PAGE", payload: 1 });
        break;
      case "joinUsForm":
        dispatch({ type: "SET_JOIN_US_FORM_PAGE_SIZE", payload: pageSize });
        dispatch({ type: "SET_JOIN_US_FORM_PAGE", payload: 1 });
        break;
      case "jobApplicants":
        dispatch({ type: "SET_JOB_APPLICANTS_PAGE_SIZE", payload: pageSize });
        dispatch({ type: "SET_JOB_APPLICANTS_PAGE", payload: 1 });
        break;
      default:
        break;
    }
  };

  // console.log(jobApplications);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {authToken?.length > 100 ? (
            <Container className="mt-5 mb-3 m-h-100">
              <h2 className="text-center">Admin content</h2>

              {/* Render Contact Us Form Data */}
              <AllContactFormData
                allContactFormData={allContactFormData}
                contactFormPageSize={contactFormPageSize}
                handlePageSizeChange={handlePageSizeChange}
                contactFormPage={contactFormPage}
                handleContactFormPageChange={handleContactFormPageChange}
                contactFormTotalPages={contactFormTotalPages}
              />

              {/* Render Join As A Partner Form Data */}
              <AllJoinUsData
                allJoinUsData={allJoinUsData}
                joinUsFormPageSize={joinUsFormPageSize}
                handlePageSizeChange={handlePageSizeChange}
                joinUsFormPage={joinUsFormPage}
                handleJoinUsFormPageChange={handleJoinUsFormPageChange}
                joinUsFormTotalPages={joinUsFormTotalPages}
              />

              {/* Render Job Applicants Form Data */}
              <JobApplications
                jobApplications={jobApplications}
                jobApplicantsPageSize={jobApplicantsPageSize}
                handlePageSizeChange={handlePageSizeChange}
                jobApplicantsPage={jobApplicantsPage}
                handleJobApplicantsPageChange={handleJobApplicantsPageChange}
                jobApplicantsTotalPages={jobApplicantsTotalPages}
                handleResumeDelete={handleResumeDelete}
                // deletedApplicantId={deletedApplicantId}
              />

              <AdminJobPosting />
            </Container>
          ) : (
            <p className="m-h-100 text-danger fs-3 d-flex justify-content-center align-items-center gap-3">
              <span>Unauthorized</span> Or <span> Your token is expired!</span>
            </p>
          )}
        </>
      )}
    </>
  );
};

export default AdminContentPage;
