import React from "react";

const SpecificServTechnologies = ({ technologies }) => {
  return (
    <section className="mt-6">
      <div className="text-center" data-aos="fade-down">
        <h1>Technologies we use</h1>
        <div className="border-b" />
      </div>
      <div
        className="mt-5 d-flex justify-content-center align-items-center gap-6 flex-wrap"
        data-aos="fade-up"
      >
        {technologies?.map((tech) => (
          <div key={tech?.id} className="mb-5">
            <img
              className={`${
                tech?.techName === "Swift" || "Xamarin"
                  ? ""
                  : "specific-serv-icon"
              }`}
              src={tech?.techImg}
              alt={tech?.techName}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default SpecificServTechnologies;
