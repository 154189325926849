import React from "react";

import { Container } from "react-bootstrap";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import IndSoftwareMarketing from "../../../components/shared/SpecificIndustries/IndSoftwareMarketing";
import SpecificIndBanner from "../../../components/shared/SpecificIndustries/SpecificIndBanner";

import NONPROFITBANNER from "../../../assets/img/non_profit_banner.png";

import DMS from "../../../assets/mntechdigital-icon/non_profit/dms_1.png";
import VMS from "../../../assets/mntechdigital-icon/non_profit/vms_2.png";
import FS from "../../../assets/mntechdigital-icon/non_profit/fs_3.png";
import IMS from "../../../assets/mntechdigital-icon/non_profit/ims_4.png";
import GMS from "../../../assets/mntechdigital-icon/non_profit/gms_5.png";
import IMS6 from "../../../assets/mntechdigital-icon/non_profit/ims_6.png";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const NonProfit = () => {
  // banner description for health care industry
  const bannerDesc = {
    id: 2018,
    indName: "Non Profit",
    indBannerDesc: `Software is an essential tool for the non-profit industry, providing organizations with the resources they need to manage their operations, engage with donors and volunteers, engage with supporters, and achieve their mission. By leveraging software, non-profits can increase their efficiency, improve their impact, and achieve greater success in serving their communities.`,
  };

  // industries
  const specificInd = [
    {
      id: 101010,
      indName: "Donor Management software",
      indDesc: `This software is used to manage donor data, track donations, and create donor reports. It enables non-profits to better engage with donors and build lasting relationships with supporters.
      `,
      indImg: DMS,
    },
    {
      id: 101011,
      indName: "Volunteer Management software",
      indDesc: `This software is used to manage volunteer data, schedule volunteers, and track volunteer hours. It enables non-profits to better engage with volunteers and ensure that they are working effectively to achieve the organization's goals.`,
      indImg: VMS,
    },
    {
      id: 101013,
      indName: "Fundraising software",
      indDesc: `Non-profits use fundraising software to plan and execute fundraising campaigns, track donations, and analyze campaign results. It enables non-profits to raise funds more efficiently and effectively.`,
      indImg: FS,
    },
    {
      id: 101014,
      indName: "Advocacy software",
      indDesc: `This software is used by non-profits to advocate for policy change, mobilize supporters, and engage with lawmakers. It enables non-profits to have a greater impact on social and political issues.`,
      indImg: IMS,
    },
    {
      id: 101015,
      indName: "Grant Management software",
      indDesc: `Non-profits use grant management software to track grant applications, manage grant funds, and generate reports for funders. It enables non-profits to manage their grant funding and ensure that they are meeting the requirements of their funders more effectively.`,
      indImg: GMS,
    },
    {
      id: 101016,
      indName: "Impact Measurement software",
      indDesc: `This software is used by non-profits to track and measure their impact on the community they serve. It enables non-profits to better understand the effectiveness of their programs and to make data-driven decisions about how to improve their services.`,
      indImg: IMS6,
    },
  ];

  return (
    <section className="mb-5">
      <PageHelmet
        title={helmetContent?.industry?.nonProfit?.title}
        description={helmetContent?.industry?.nonProfit?.desc}
      />
      <SpecificIndBanner INDBANNER={NONPROFITBANNER} bannerDesc={bannerDesc} />
      <Container>
        <IndSoftwareMarketing
          specificInd={specificInd}
          indName={bannerDesc?.indName}
        />
        <LetUsHelp />
      </Container>
    </section>
  );
};

export default NonProfit;
