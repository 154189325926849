import React from "react";

const CareerIntro = () => {
  return (
    <div className="text-center">
      <h3 className="fw-bold">Our Job Offers</h3>
      <p className="mntech-secondary-clr-text my-4">
        <b>Email:</b> contact@mntechdigital.com
      </p>
      <p className="my-4">
        Join us, we offer you an extraordinary chance to learn, to develop and
        to be part of an exciting experience and team.
      </p>
    </div>
  );
};

export default CareerIntro;
