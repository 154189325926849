import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaLaptop } from "react-icons/fa";
import { AiOutlineMobile, AiOutlineBarChart } from "react-icons/ai";
import { CiGrid41 } from "react-icons/ci";
import { RxKeyboard } from "react-icons/rx";
import { SlRocket } from "react-icons/sl";
import SERVICESIMG from "../../../assets/img/services_banner.png";
import LetUsHelp from "../../../components/shared/LetUsHelp/LetUsHelp";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

import "./Services.css";
import LoadingSpinner from "../../../components/shared/LoadingSpinner/LoadingSpinner";
import { Link } from "react-router-dom";
import PageHelmet from "../../../components/shared/PageHelmet/PageHelmet";
import { helmetContent } from "../../../utils/helmetContent";

const Services = () => {
  const [services, setServices] = useState([]);
  const [isServicesLoading, setIsServicesLoading] = useState(false);

  // services icons
  const laptop = <FaLaptop className="service-icon" />;
  const mobile = <AiOutlineMobile className="service-icon" />;
  const barchart = <AiOutlineBarChart className="service-icon" />;
  const grid = <CiGrid41 className="service-icon" />;
  const keyboard = <RxKeyboard className="service-icon" />;
  const rocket = <SlRocket className="service-icon" />;

  // get services from backend
  useEffect(() => {
    const servicesFn = async () => {
      setIsServicesLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/services`
        );
        setServices(response?.data?.results);
        setIsServicesLoading(false);
      } catch (error) {
        console.log(error);
        setIsServicesLoading(false);
      }
    };
    servicesFn();
  }, []);

  // console.log(isServicesLoading, services);

  return (
    <section>
      <PageHelmet
        title={helmetContent?.ourServices?.title}
        description={helmetContent?.ourServices?.desc}
      />
      {isServicesLoading && <LoadingSpinner />}
      {!isServicesLoading && (
        <>
          <div className="w-100 h-100 mb-5">
            <img
              className="w-100 h-100"
              src={SERVICESIMG}
              alt="serviceBanner"
            />
          </div>
          <Container>
            {services?.length ? (
              <>
                <h2 className="text-center">Technological Soulutions</h2>
                <div className="border-b" />
              </>
            ) : (
              ""
            )}

            <Row xs={1} md={2} lg={3} className="g-4 pt-5">
              {/* see services starts */}
              {services?.map((serv, index) => (
                <Col key={serv?.service_code} data-aos="fade-up">
                  <Link
                    to={`/service/${
                      (serv?.service_code === 1 && "web-dev") ||
                      (serv?.service_code === 2 && "mobile-app") ||
                      (serv?.service_code === 3 && "digital-marketing") ||
                      (serv?.service_code === 4 && "ui-ux") ||
                      (serv?.service_code === 5 && "data-entry") ||
                      (serv?.service_code === 6 && "qa")
                    }`}
                    className="no-underline mntech-secondary-clr-text mntech-primary-clr-text-hover"
                  >
                    <Card className="border-0 card-shadow card-hover cursor-pointer px-3">
                      {/* <div className="mt-4 rounded-circle rounded-lg border  icon-container">
                        <h2 className="icon-size card-logo">
                          {(index === 0 && laptop) ||
                            (index === 1 && mobile) ||
                            (index === 2 && barchart) ||
                            (index === 3 && grid) ||
                            (index === 4 && keyboard) ||
                            (index === 5 && rocket)}
                        </h2>
                      </div> */}
                      <div className="mx-auto mt-4 service-icon-container rounded-circle rounded-lg border-mntech card-logo">
                        {(index === 0 && laptop) ||
                          (index === 1 && mobile) ||
                          (index === 2 && barchart) ||
                          (index === 3 && grid) ||
                          (index === 4 && keyboard) ||
                          (index === 5 && rocket)}
                      </div>
                      <Card.Body className="service-card-height">
                        <Card.Title className="service-title text-center">
                          {serv?.service_name}
                        </Card.Title>
                        <Card.Text className="mt-4 mntech-secondary-clr-text line-height-2 ta-same">
                          {serv?.service_desc}
                        </Card.Text>
                        <div className="mt-5 position-relative">
                          <p className="no-underline mntech-primary-clr-text mntech-primary-clr-text-hover position-fixed bottom-read-more">
                            <span>Read More</span>
                            <span>
                              <MdOutlineKeyboardArrowRight className="fs-4" />
                            </span>
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ))}
              {/* see services ends */}
            </Row>
            <>
              <LetUsHelp />
            </>
          </Container>
        </>
      )}
    </section>
  );
};

export default Services;
