import React from "react";

const SpecificServDetails = ({ details }) => {
  const { firstParagraph, secondParagraph } = details;
  return (
    <section className="mt-6 md-f-col d-flex d-flex-center-both gap-3 gap-md-4 gap-lg-5">
      <div className="specific-desc" data-aos="fade-right">
        <p className="text-justify">{firstParagraph}</p>
      </div>
      <div className="specific-desc" data-aos="fade-left">
        <p className="text-justify">{secondParagraph}</p>
      </div>
    </section>
  );
};

export default SpecificServDetails;
