// AdminContentReducer.js
const initialState = {
  isLoading: true,
  allContactFormData: [],
  allJoinUsData: [],
  jobApplications: [],
  authToken: null,
  contactFormPage: 1,
  joinUsFormPage: 1,
  jobApplicantsPage: 1,
  contactFormTotalPages: 1,
  joinUsFormTotalPages: 1,
  jobApplicantsTotalPages: 1,
  contactFormPageSize: 10,
  joinUsFormPageSize: 10,
  jobApplicantsPageSize: 10,
};

const adminContentReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_AUTH_TOKEN":
      return { ...state, authToken: action.payload };
    case "SET_ALL_CONTACT_FORM_DATA":
      return { ...state, allContactFormData: action.payload };
    case "SET_ALL_JOIN_US_DATA":
      return { ...state, allJoinUsData: action.payload };
    case "SET_JOB_APPLICATIONS":
      return { ...state, jobApplications: action.payload };
    case "SET_CONTACT_FORM_PAGE":
      return { ...state, contactFormPage: action.payload };
    case "SET_JOIN_US_FORM_PAGE":
      return { ...state, joinUsFormPage: action.payload };
    case "SET_JOB_APPLICANTS_PAGE":
      return { ...state, jobApplicantsPage: action.payload };
    case "SET_CONTACT_FORM_TOTAL_PAGES":
      return { ...state, contactFormTotalPages: action.payload };
    case "SET_JOIN_US_FORM_TOTAL_PAGES":
      return { ...state, joinUsFormTotalPages: action.payload };
    case "SET_JOB_APPLICANTS_TOTAL_PAGES":
      return { ...state, jobApplicantsTotalPages: action.payload };
    case "SET_CONTACT_FORM_PAGE_SIZE":
      return { ...state, contactFormPageSize: action.payload };
    case "SET_JOIN_US_FORM_PAGE_SIZE":
      return { ...state, joinUsFormPageSize: action.payload };
    case "SET_JOB_APPLICANTS_PAGE_SIZE":
      return { ...state, jobApplicantsPageSize: action.payload };
    case "REMOVE_DELETED_APPLICATION":
      return {
        ...state,
        jobApplications: state.jobApplications.filter(
          (applicant) => applicant.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export { initialState, adminContentReducer };
