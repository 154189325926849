import React from "react";
import TRIANGLE from "../../assets/mntechdigital-icon/triangle.png";

const QandAOffer = () => {
  const qAOffers = [
    {
      id: 5900,
      offerName: "Funcational Testing",
    },
    {
      id: 5901,
      offerName: "Comparability Testing",
    },
    {
      id: 5902,
      offerName: "Automated Testing",
    },
    {
      id: 5903,
      offerName: "Technical Testing",
    },
    {
      id: 5904,
      offerName: "Security Testing",
    },
  ];

  return (
    <section className="mt-6">
      <div data-aos="fade-down">
        <p className="text-center mb-0">To make sure quality</p>
        <h1 className="text-center mt-0 fw-semibold">We Offered</h1>
        <div className="border-b" />
      </div>
      <div className="w-100 d-flex justify-content-center" data-aos="fade-up">
        <div className="w-75 pb-md-5 d-flex flex-wrap">
          {qAOffers.map((offer) => (
            <div
              key={offer?.id}
              className="w-50 d-flex align-items-center gap-3 my-3 px-5 mt-5"
            >
              <img className="triangle-icon" src={TRIANGLE} alt="" />
              <span className="fs-5">{offer?.offerName}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default QandAOffer;
